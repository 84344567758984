import React from "react";

import "./DotLoading.scss";

function DotLoading() {
    return (
        <div className="loadingio-spinner-ellipsis-js7qwfxgzlb">
            <div className="ldio-owgbjxhwuko">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

export default DotLoading;
