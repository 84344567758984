import { CaretDownOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import axios from "axios";

// Style
import "./HotelSectionDestination.scss";

// Local Imports
import { appUrl } from "../../../config/appUrl";
import Dropdown from "../PortfolioDropdown/Dropdown";

function HotelSectionDestination(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [destination, setDestination] = useState();
    const [hotels, setHotels] = useState([]);

    useEffect(() => {
        Aos.init({ duration: 1200 });
        axios
            .get(`${appUrl.url}/hotels?key=${appUrl.key}`)
            .then((response) => setHotels(response.data));
    }, []);

    // Setting up the animations for cards
    const animations = (num) => {
        let direction;
        let rgtAnimation = [
            2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35, 38, 41, 44, 47, 50, 53,
            56, 59, 62, 65, 68, 71, 74, 77, 80, 83, 86, 89, 92, 95, 98,
        ];
        if (num % 3 === 0) {
            direction = "fade-right";
        } else if (rgtAnimation.includes(num)) {
            direction = "fade-left";
        } else if (num % 3 === 1 || num % 2 === 0) {
            direction = "fade-up";
        }
        return direction;
    };

    const showModal = () => {
        switch (isOpen) {
            case false:
                setIsOpen(true);
                break;
            case true:
                setIsOpen(false);
                break;
            default:
                setIsOpen(false);
        }
    };

    const hotelDest = props.hotelData.map((data, index) => {
        return (
            <div
                className="dest-card"
                key={data.id}
                data-aos={`${animations(index)}`}
            >
                <div className="card-main">
                    <div
                        className="card-icon"
                        style={{ background: `url(${data.icon_url})` }}
                    ></div>
                    <div className="card-name">{data.country}</div>
                </div>
                <div className="port-content">
                    <div className="upper">
                        <div
                            className="button"
                            onClick={() => {
                                setDestination(data.id);
                                showModal();
                            }}
                        >
                            Select Hotel
                            <CaretDownOutlined className="dropdwon_icon" />
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <>
            {isOpen && (
                <Dropdown
                    ShowModal={showModal}
                    destination={destination}
                    hotels={hotels}
                    title="dest"
                />
            )}
            <div className="hotel-section-dest">{hotelDest}</div>
        </>
    );
}

export default HotelSectionDestination;

// import { CaretDownOutlined } from "@ant-design/icons";
// import Aos from "aos";
// import Dropdown from "../PortfolioDropdown/Dropdown";
// import { useEffect } from "react";

// import "./HotelSectionDestination.scss";
// import { useState } from "react";
// import axios from "axios";
// import { appUrl } from "../../config/appUrl";

// function HotelSectionDestination(props) {
//     const [hotels, setHotels] = useState([]);
//     const [destination, setDestination] = useState();
//     const [isOpen, setIsOpen] = useState(false);

//     useEffect(() => {
//         Aos.init({ duration: 1800 });
//         axios
//             .get(`${appUrl.url}/hotels?key=${appUrl.key}`)
//             .then((response) => setHotels(response.data));
//     }, []);

//     // Setting up the animations for cards
//     const animations = (num) => {
//         let direction;
//         let rgtAnimation = [
//             2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35, 38, 41, 44, 47, 50, 53,
//             56, 59, 62, 65, 68, 71, 74, 77, 80, 83, 86, 89, 92, 95, 98,
//         ];
//         if (num % 3 === 0) {
//             direction = "fade-right";
//         } else if (rgtAnimation.includes(num)) {
//             direction = "fade-left";
//         } else if (num % 3 === 1 || num % 2 === 0) {
//             direction = "fade-up";
//         }
//         return direction;
//     };

//     const showModal = () => {
//         switch (isOpen) {
//             case false:
//                 setIsOpen(true);
//                 break;
//             case true:
//                 setIsOpen(false);
//                 break;
//             default:
//                 setIsOpen(false);
//         }
//     };

//     const hotelBrand = props.hotelData.map((data, index) => {

//         console.log(data)

//         return (
//             <div
//                 key={data.id}
//                 className="port-card"
//                 data-aos={`${animations(index)}`}
//             >
//                 <div
//                     className="port-imgBx-destination"
//                     style={{ background: `url(${data.icon_url})` }}
//                 >
//                     {data.name}
//                 </div>
//                 <div className="port-content">
//                     <div className="upper">
//                         <div
//                             className="button"
//                             onClick={() => {
//                                 setDestination(data.id);
//                                 showModal();
//                             }}
//                         >
//                             Select Your Hotel
//                             <CaretDownOutlined className="dropdwon_icon" />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         );
//     });

//     return (
//         <>
//             {isOpen && (
//                 <Dropdown
//                     ShowModal={showModal}
//                     destination={destination}
//                     hotels={hotels}
//                 />
//             )}
//             <div className="hotel-section-brands">{hotelBrand}</div>
//         </>
//     );
// }

// export default HotelSectionDestination;
