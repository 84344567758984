import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isCardAnimated: false,
    isOverlayOn: false,
    cardName: null
};

const aboutUsSlice = createSlice({
    name: "aboutUs",
    initialState,
    reducers: {
        setCardAnimation: (state, action) => {
            state.isCardAnimated = action.payload;
        },

        setOverlay: (state, action) => {
            state.isOverlayOn = action.payload;
        },

        setCardName: (state, action) => {
            state.cardName = action.payload;
        }
    },
});

export const { setCardAnimation, setOverlay, setCardName } = aboutUsSlice.actions

export default aboutUsSlice.reducer