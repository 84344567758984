import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    brands: [],
    destinations: [],
    brandsAll: [],
    destinationsAll: [],
    searchType: "brands"
}

const portfolioSlice = createSlice({
    name: "portfolio",
    initialState,
    reducers: {
        setBrands: (state, action) => {
            state.brands = action.payload;
        },
        setBrandsAll: (state, action) => {
            state.brandsAll = action.payload;
        },
        setDestinations: (state, action) => {
            state.destinations = action.payload;
        },
        setDestinationsAll: (state, action) => {
            state.destinationsAll = action.payload;
        },
        setSearchType: (state, action) => {
            state.searchType = action.payload;
        }
    }
})

export const { setBrands, setDestinations, setBrandsAll, setDestinationsAll, setSearchType } = portfolioSlice.actions;

export default portfolioSlice.reducer;