export const valuesdata = [
    {
        id: 1,
        img: "/images/devil.png",
        title: "INTEGRITY",
        classes: "one",
        content:
            "Why is this one the first?<p style='margin: 10px 0px'>Well, lets just say that there aint no joy in doing business without it. If we dont deliver our promises to you, then you dont deliver your promises to your client, then he gets upset with you and then you get upset with us.</p> You see where we are going with this...right?",
        aos: "fade-up-right",
    },
    {
        id: 2,
        img: "/images/bells.png",
        title: "PROMPTNESS",
        classes: "two",
        content:
            "When you send an email at 3 am & a follow up for the same at 9 am, you surely are in need of urgent assistance. We get that.<p style='margin: 10px 0px'>Thats why our turn around time to anything that you require from us is less than 4 hours.</p> Except when we are watching Netflix! That thing is addictive!",
        aos: "fade-up",
    },
    {
        id: 3,
        img: "/images/split.png",
        title: "FLEXIBILITY",
        classes: "three",
        content:
            "None of our team members can do a full split. And It's not like we didnt try! But what we do do is listen to you patiently, understand what your client wants & then devise a possible solution that actually works!<p style='margin: 10px 0px'>And if that requires us to twist some arms or break some... well..lets just say we get it DONE. Period.</p>",
        aos: "fade-up-left",
    },
    {
        id: 4,
        img: "/images/doors.png",
        title: "SELECTIVENESS",
        classes: "four",
        content:
            "Just like our Hotel partners, we also like to handpick our Travel Agent Partners.<br /> 'Cos we only work with a limited number of agents, we answer their calls, give them both our ears to listen & never lose our calm.<p style='margin: 10px 0px'>Ofcourse, this goes out the window as soon as we get back home to our respective spouses but isn't that how GOD intended it?</p>",
        aos: "fade-up-right",
    },
    {
        id: 5,
        img: "/images/armw.png",
        title: "MAXIMUM EFFORT!",
        classes: "five",
        content:
            "You don't build credibility overnight.<p style='margin: 10px 0px'>And that comes with Positivty, Passion, Perseverance & a few other big words. We always give our 100%, unless we give 80%.</p>But whats 20% really?",
        aos: "fade-up",
    },
    {
        id: 6,
        img: "/images/phone.png",
        title: "OLD SCHOOLISH",
        classes: "six",
        content:
            "Water water everywhere, not a drop to drink.. Info Info everywhere, but what to do with it?<br /> Internet has so much of information that its impossible to put it in the right context. But not with us. We are old school. <br /> When you need an info from us, just pick up the phone & call...or come over & get a few beers along while you are at it!",
        aos: "fade-up-left",
    },
    {
        id: 7,
        img: "/images/party.png",
        title: "FUN",
        classes: "seven",
        content:
            "Fun is what we have from 9 am till 6 pm - when we work! Other times its usally Beers & Conversations that keep our spirits high!<p style='margin: 10px 0px'>But can we tell you a secret? Sometimes the two get mixed up! :P...Shhh......</p>",
        aos: "fade-up-right",
    },

    {
        id: 8,
        img: "/images/awes.png",
        title: "AWESOMETASTIC",
        classes: "eight",
        content:
            "Now how cool is that word?<p style='margin: 10px 0px'>Does it not deserve its own block! Thats why it stays. Also, we coined this term. So, 'yey' to us & 'boo hoo' to those who still like to use Awesome & Fantastic in a sentence. Catch up guys!</p>",
        aos: "fade-up",
    },
];
