import { useEffect, useState } from "react";

// Styles
import "./AboutUsCard.scss";

function AboutUsCard(props) {
    useEffect(() => {
        if (props.activeCard[props.cardNumber] === 1) {
            setCardIsAnimated(true);
        } else if (props.activeCard[props.cardNumber] === 0) {
            setCardIsAnimated(false);
        }
    }, [props.activeCard, props.cardNumber]);

    // Card Animation State
    const [cardIsAnimated, setCardIsAnimated] = useState(false);
    let cardOption = cardIsAnimated
        ? { marginBottom: "-40px" }
        : { marginTop: "70px" };

    // Toggling Animation
    function handleAnimateCard() {
        setCardIsAnimated(!cardIsAnimated);
        if (props.cardNumber === "0") {
            if (cardIsAnimated) {
                props.activeCardHandler([0, 0, 0]);
            } else {
                props.activeCardHandler([1, 0, 0]);
            }
        } else if (props.cardNumber === "1") {
            if (cardIsAnimated) {
                props.activeCardHandler([0, 0, 0]);
            } else {
                props.activeCardHandler([0, 1, 0]);
            }
        } else if (props.cardNumber === "2") {
            if (cardIsAnimated) {
                props.activeCardHandler([0, 0, 0]);
            } else {
                props.activeCardHandler([0, 0, 1]);
            }
        }
    }

    return (
        <div
            className={`about-us-container ${
                cardIsAnimated && "about-us-container-active"
            }`}
            /* Adding margin to bottom if card is extended */
            style={
                cardIsAnimated
                    ? { zIndex: props.cardIndex, marginBottom: "150px" }
                    : { zIndex: props.cardIndex }
            }
        >
            <div
                className="about-us-profile"
                style={{
                    backgroundImage: `linear-gradient(to right bottom, rgba(5, 5, 15, .6), rgba(5, 5, 15, .6)), url(${props.profile})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <p className="about-us-profile-info-name">{props.name}</p>
                <p className="about-us-profile-info-desc">{props.position}</p>
            </div>
            <div className="about-us-details">
                <p
                    dangerouslySetInnerHTML={{
                        __html: props.aboutDesc,
                    }}
                ></p>
            </div>
            <div className="about-us-read" style={cardOption}>
                <button onClick={handleAnimateCard}>{`${
                    cardIsAnimated ? "Read Less" : "Read More"
                }`}</button>
            </div>
        </div>
    );
}

export default AboutUsCard;
