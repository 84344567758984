//*************** New Component ***************//

import { Fragment } from "react";
import { HiLocationMarker } from "react-icons/hi";

// Styles
import styles from "./ContactUs.module.scss";

// Local Imports
import Header from "../../components/UI/Header/Header";
import Social from "../../components/UI/Social/Social";

const ContactUs = () => {
    return (
        <Fragment>
            <Header />
            <Social />
            <main className={styles.contact_us}>
                <div className={styles.card}>
                    <h2>COME... VISIT US SOMETIME!</h2>
                    <a
                        href="https://goo.gl/maps/muD1j4PwPQnj7rZm7"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <HiLocationMarker
                            color="#3AA756"
                            style={{ cursor: "pointer" }}
                        />
                    </a>
                    <p className={styles.company_name}>
                        LX MARKETING PVT. LTD.
                    </p>
                    <p className={styles.company_address}>
                        21, Second Floor, DDA Market, Nelson Mandela Marg,
                        Munirka Vihar, New Delhi, India
                    </p>
                </div>
                <div className={styles.contact__divider}></div>
                <div className={styles.card}>
                    <h2>IN A HURRY TO CONNECT?</h2>
                    <svg
                        width="120"
                        height="95"
                        viewBox="0 0 120 95"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M60 1.02584e-06C37.3 1.02584e-06 16.7 8.9 1.45 23.35C0.55 24.25 0 25.5 0 26.9C0 28.3 0.55 29.55 1.45 30.45L13.85 42.85C14.7865 43.776 16.0478 44.2995 17.3648 44.3089C18.6819 44.3182 19.9504 43.8126 20.9 42.9C24.85 39.2 29.35 36.1 34.2 33.65C35.85 32.85 37 31.15 37 29.15V13.65C44.25 11.25 51.95 10 60 10C67.95 10 75.7 11.25 82.95 13.6V29.1C82.95 31.05 84.1 32.8 85.75 33.6C90.65 36.05 95 39.2 99.1 42.85C100 43.75 101.25 44.25 102.5 44.25C104 44.25 105.25 43.7 106.15 42.8L118.55 30.4C119.45 29.5 120 28.25 120 26.85C120 25.45 119.4 24.25 118.5 23.35C102.72 8.34682 81.7742 -0.0134826 60 1.02584e-06ZM45 20V35C45 35 15 60 15 75V95H105V75C105 60 75 35 75 35V20H65V30H55V20H45ZM60 45C65.3043 45 70.3914 47.1071 74.1421 50.8579C77.8929 54.6086 80 59.6957 80 65C80 70.3043 77.8929 75.3914 74.1421 79.1421C70.3914 82.8929 65.3043 85 60 85C54.6957 85 49.6086 82.8929 45.8579 79.1421C42.1071 75.3914 40 70.3043 40 65C40 59.6957 42.1071 54.6086 45.8579 50.8579C49.6086 47.1071 54.6957 45 60 45ZM60 52.5C56.6848 52.5 53.5054 53.817 51.1612 56.1612C48.817 58.5054 47.5 61.6848 47.5 65C47.5 68.3152 48.817 71.4946 51.1612 73.8388C53.5054 76.183 56.6848 77.5 60 77.5C63.3152 77.5 66.4946 76.183 68.8388 73.8388C71.183 71.4946 72.5 68.3152 72.5 65C72.5 61.6848 71.183 58.5054 68.8388 56.1612C66.4946 53.817 63.3152 52.5 60 52.5Z"
                            fill="#BB0000"
                        />
                    </svg>
                    <div className={styles.contact_numbers}>
                        <a href="tel:+919711983158">+91 9711983158</a>
                        <a href="tel:+919899699991">+91 9899699991</a>
                    </div>
                    <div className={styles.emails}>
                        <a href="mailto:gaurav.s@luxuryliving.in">
                            gaurav.s@luxuryliving.in
                        </a>
                        <a href="mailto:a@luxuryliving.in">a@luxuryliving.in</a>
                    </div>
                </div>
            </main>
        </Fragment>
    );
};

export default ContactUs;
