import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value : ""
};

const offerButtonTagSlice = createSlice({
    name: "offerButtonTag",
    initialState,
    reducers : {
        setOfferButtons : (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setOfferButtons } = offerButtonTagSlice.actions;

export default offerButtonTagSlice.reducer;