import { configureStore } from "@reduxjs/toolkit";
import searchReducer from "./indexReducer";
import aboutUsReducer from "./aboutUsReducer";
import offerButtonTagReducer from "./offerButtonTagReducer";
import portfolioReducer from "./portfolioReducer";
import hotelListReducer from "./hotelListReducer";
import offersReducer from "./offersReducer";

export const store = configureStore({
    reducer: {
        search: searchReducer,
        aboutUs: aboutUsReducer,
        offerButtonTag: offerButtonTagReducer,
        portfolio: portfolioReducer,
        hotelList: hotelListReducer,
        offers: offersReducer,
    },
});
