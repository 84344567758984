import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

// Styles
import "./PortfolioVideo.scss";

// Local Imports
import VerticalTitle from "../../UI/VerticalTitle/VerticalTitle";
import Banner from "../../UI/Banner/Banner";

//! Deprecated ( No longer in use )
// import imageOne from "../../../images/Banners/BannerOne.png";
// import imageTwo from "../../../images/Banners/BannerTwo.png";

const PortfolioVideo = () => {
    const navigate = useNavigate();

    //! Deprecated ( No longer in use )
    // const [bannerImage, setBannerImage] = useState(imageOne);

    //? This Banner code only works for 2 banner may need to be dynamic later // */

    // useEffect(() => {
    //     setTimeout(() => {
    //         setBannerImage(imageTwo);
    //     }, 10000);
    // }, []);

    // useEffect(() => {
    //     const newTimeout = setTimeout(() => {
    //         setBannerImage("");
    //         clearTimeout(newTimeout);
    //     }, 15000);
    // });

    // Navigate to portfolio section
    const titleClickHandler = () => {
        navigate("/luxury-living-portfolio");
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <section className="video-container">
            {/* Pass prop value (top) is you want to have manual y-axis alignment of vertical title */}
            <VerticalTitle
                title="&nbsp;&nbsp;portfolio&nbsp;"
                onClick={titleClickHandler}
                clickable={true}
                top={"274px"}
                right="-324px"
            />
            <div className="video-main">
                <video
                    src="https://luxury-living-pictures.s3.amazonaws.com/media/video/portfolio.mp4"
                    muted
                    autoPlay
                    loop
                    controls
                />

                {/* <Banner image={imageTwo} /> */}
                <p className="video-button" onClick={titleClickHandler}>
                    Click To View Portfolio
                </p>
            </div>
        </section>
    );
};

export default PortfolioVideo;
