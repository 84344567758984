import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setHotelListData } from "../../redux/hotelListReducer";

// Styles
import "./style.scss";

// Local imports
import Header from "../../components/UI/Header/Header";
import Social from "../../components/UI/Social/Social";
import Scroll from "../../utils/ScrollToTop/Scroll";
import ContactUs from "../Contactus/ContactUs";
import HeroSection from "../../components/HomePage/HeroSection/HeroSection";
import StarWarsIntro from "../../components/HomePage/StarWarsIntro/StarWarsIntro";
import OurValues from "../../components/HomePage/OurValues/OurValues";
import PortfolioVideo from "../../components/HomePage/PortfolioVideo/PortfolioVideo";
import Testimonials from "../../components/HomePage/Testimonials/Testimonials";
import { appUrl } from "../../config/appUrl";
import Overlay from "../../components/UI/Overlay/Overlay";

const Home = () => {
    // Redux
    const dispatch = useDispatch();
    const isHotelLoaded = useSelector((state) => state.hotelList.hotelList);

    // State
    const [hotelData, setHotelData] = useState(false);

    useEffect(() => {
        document.title = "Luxury Living";

        if (isHotelLoaded.length !== 0) {
            dispatch(setHotelListData(isHotelLoaded));
            setHotelData(true);
        } else {
            // Getting All the hotels from the API
            axios.get(`${appUrl.url}/hotels?key=${appUrl.key}`).then((res) => {
                dispatch(setHotelListData(res.data));
                setHotelData(true);
            });
        }
    }, [isHotelLoaded, dispatch]);

    return (
        <div className="home_page">
            {!hotelData && (
                <Overlay loading={true} fullScreen={true} size={120} />
            )}
            <Header />
            <div className="home_page_wrapper">
                {/* Home Page Section 1 (Hero section) */}
                <HeroSection />

                {/* Home Page Section 2 (Star Wars alike intro) */}
                <StarWarsIntro />

                {/* Home Page Section 3 (Our Values) */}
                <OurValues />

                {/* Portfolio Section 4 (Portfolio) */}
                <PortfolioVideo />

                {/* Testimonials Section 5 */}
                <Testimonials />

                {/* Contact Us Section 6 */}
                <ContactUs />
            </div>
            <Social />
            <Scroll />
        </div>
    );
};

export default Home;
