import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Country from "./Country/Country";
import "./CountryList.scss";

// Inner Component for Country component
function CountryListWithoutCity({ country, allHotels }) {
    const [isListVisible, setIsListVisible] = useState(false);

    const navigate = useNavigate();

    // Disappear or add the hotel list when country name is clicked
    function handleCountryClick() {
        setIsListVisible(!isListVisible);
    }

    return (
        <div className="country-without-city">
            <h1
                className={`cwc-country-name ${isListVisible && "cwc-active"}`}
                onClick={handleCountryClick}
            >
                {country}
            </h1>
            {isListVisible && (
                <ul className="cwc-hotels-list">
                    {allHotels.map((item) => {
                        if (item.country === country) {
                            return (
                                <li
                                    onClick={() =>
                                        navigate(
                                            `/luxury-living-portfolio/${item.url_param_key}`
                                        )
                                    }
                                    key={item.url_param_key}
                                >
                                    <p>
                                        {item.name},&nbsp;
                                        <span>{item.city}</span>
                                        {item.collaboration && (
                                            <span className="new-tag">NEW</span>
                                        )}
                                    </p>
                                </li>
                            );
                        } else {
                            return false;
                        }
                    })}
                </ul>
            )}
        </div>
    );
}

function AsiaList({ allHotels, continentName }) {
    const [countries, setCountries] = useState();
    const [allRelatedCountries, setAllRelatedCountries] = useState();
    const countriesWithoutCities = {
        Bhutan: 1,
        Maldives: 1,
        Singapore: 1,
        India: 1,
        Japan: 1,
        Laos: 1,
        Philippines: 1,
        "Sri Lanka": 1,
        Mauritius: 1,
        Morocco: 1,
        Austria: 1,
        Montenegro: 1,
        China: 1,
        Cambodia: 1,
    };

    useEffect(() => {
        const unique = (value, index, self) => {
            return self.indexOf(value) === index;
        };
        // Filtering countries by continent
        const allCountries = allHotels.filter(
            (item) => item.continent === continentName
        );
        setAllRelatedCountries(allCountries);

        // Getting unique values of countries (means no repetition of country names in array)
        const countries = allCountries.map((country) => country.country).sort();

        const uniqueCountries = countries.filter(unique);

        setCountries(uniqueCountries);
    }, [allHotels, continentName]);

    return (
        <ul className="country-list-box">
            {countries?.map((item) => {
                if (countriesWithoutCities[item]) {
                    return (
                        <CountryListWithoutCity
                            key={item}
                            country={item}
                            allHotels={allHotels}
                        />
                    );
                }
                return (
                    <Country
                        key={item}
                        countryName={item}
                        hotelList={allRelatedCountries}
                        allHotels={allHotels}
                    />
                );
            })}
        </ul>
    );
}

export default AsiaList;
