import "./VerticalTitle.scss";

const VerticalTitle = ({ title, onClick, clickable, top, right }) => {
    return (
        <p
            className={`vertical-title ${clickable && "title-click"}`}
            onClick={onClick}
            style={{ top: `${top}`, right: `${right}` }}
        >
            {title}
        </p>
    );
};

export default VerticalTitle;
