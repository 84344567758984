import React, { useEffect, useState, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setHotelListData } from "../../redux/hotelListReducer";

// Styles
import "./HotelsCollabs.scss";

// Local Imports
import Header from "../../components/UI/Header/Header";
import Social from "../../components/UI/Social/Social";
import Loading from "../../utils/Loading/Loading";
import { appUrl } from "../../config/appUrl";

const HotelsCards = React.lazy(() =>
    import("../../components/HotelCollabCard/HotelsCards")
);

const HotelsCollabs = () => {
    const [data, setData] = useState([]);
    const hotelData = useSelector((state) => state.hotelList.hotelList);
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = "Luxury Living - Hotels collabs";

        // check if there's no hotel data in redux state then make the api call
        if (hotelData.length === 0) {
            // API Call To get the hotels data
            async function getData() {
                try {
                    const response = await fetch(
                        `${appUrl.url}/hotels?key=${appUrl.key}`
                    );
                    const data = await response.json();
                    dispatch(setHotelListData(data));

                    // Sorting the hotels data by dates
                    // data.sort(function (a, b) {
                    //     return (
                    //         Date.parse(b.created_on) - Date.parse(a.created_on)
                    //     );
                    // });
                    setData(data);
                } catch (err) {
                    console.log(err);
                }
            }

            // Function call
            getData();
        } else {
            // Sorting the hotels data by dates
            const data = hotelData.map((item) => item);
            data.sort(function (a, b) {
                return Date.parse(b.created_on) - Date.parse(a.created_on);
            });
            setData(data);
        }
    }, [hotelData, dispatch]);

    return (
        <Suspense fallback={<Loading height="100vh" />}>
            <div className="hotels_collabs">
                <Header />
                <div className="hotels_collabs_wrapper">
                    <h3>New Collaborations</h3>
                    <div className="new_hotels_container">
                        {data.map((d) => {
                            if (d.collaboration === true) {
                                return <HotelsCards key={d.id} d={d} />;
                            }
                            return null;
                        })}
                    </div>
                </div>
                <Social />
            </div>
        </Suspense>
    );
};

export default HotelsCollabs;
