import "./HeroSection.scss";

const HeroSection = () => {
    return (
        <section className="hero-section">
            <div className="hero-container">
                <h1 className="hero-heading">
                    <span>Luxury</span> <span>Living</span>
                </h1>
                <div className="hero-para-container">
                    <p className="hero-para">
                        Our top game is to make it easy for our Travel Agent
                        Partners to book 'Luxury Hotels' Worldwide
                    </p>
                    <p className="hero-para">
                        Our medium game is to do this with finnese, subtelty &
                        without much hangup
                    </p>
                    <p className="hero-para">
                        Our bottom game is to do 30 push-ups in one go.'
                    </p>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
