import { ArrowLeftOutlined } from "@ant-design/icons";
import { FaQuestion, FaShareAlt, FaArrowDown } from "react-icons/fa";
import { BiGift } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";

// Style
import "./HotelDesc.scss";

// Local Imports
import ShareModal from "../../components/ShareComponent/ShareModal";
import { appUrl } from "../../config/appUrl";

function HotelDesc() {
    const navigate = useNavigate();
    const location = useLocation();
    const [hotelData, setHotelData] = useState();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [tooltipText, setTooltipText] = useState("");
    const [showShare, setShowShare] = useState(false);
    const [tooltipSpace, setTooltipSpace] = useState("0px");

    // getting id from url of the site
    const id = location.pathname.split("/")[2];

    const settings = {
        dots: true,
        infinite: true,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3000,
        cssEase: "linear",
    };

    window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
    });

    useEffect(() => {
        document.title = `${hotelData?.name} | Luxury Living`;

        if (!hotelData) {
            axios
                .get(`${appUrl.url}/hotel?pk=${id}&&key=${appUrl.key}`)
                .then((response) => {
                    setHotelData(response.data);
                });
        }
    }, [id, hotelData]);

    // Navigate back
    function back() {
        navigate("/luxury-living-portfolio");
    }

    function firstLetterSizeIncrease(string) {
        let firstLetter = string[0];
        let wordWithoutFirst = string.slice(1, string.length);
        return (
            <p className="hotel-desc-card-hotelName">
                <span style={{ fontSize: "4.4rem" }}>{firstLetter}</span>
                {wordWithoutFirst}
                <br />
                <span style={{ fontSize: "1.8rem", letterSpacing: "2px" }}>
                    {hotelData?.city}, {hotelData?.country}
                </span>
            </p>
        );
    }

    function handleTooltip(event) {
        const buttonName = event.target.classList.value;

        if (buttonName === "hotel-desc-download-icon") {
            setTooltipSpace("40px");
            setTooltipText("Download");
        } else if (buttonName === "hotel-desc-share-icon") {
            setTooltipSpace("116px");
            setTooltipText("Share");
        } else if (buttonName === "hotel-desc-inquire-icon") {
            setTooltipSpace("190px");
            setTooltipText("Enquire");
        } else if (buttonName === "hotel-desc-active-offers-icon") {
            setTooltipSpace("266px");
            setTooltipText("Active offers");
        }
    }

    function handleTooltipLeave() {
        setTooltipText("");
    }

    function handleShare() {
        setShowShare(true);
    }

    return (
        <div className="hotel-desc-container">
            <div className="back-btn" onClick={back}>
                <ArrowLeftOutlined className="back-icon" />
            </div>
            {windowWidth > 750 ? (
                <div className="hotel-desc-card-container">
                    <div
                        className="hotel-desc-card"
                        style={{
                            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%), url(${hotelData?.pictures[0].url})`,
                        }}
                    >
                        {firstLetterSizeIncrease(
                            hotelData?.name ? hotelData.name : "Loading..."
                        )}
                    </div>
                    <div
                        className="hotel-desc-card"
                        style={{
                            backgroundImage: `url(${hotelData?.pictures[1].url})`,
                        }}
                    ></div>
                    <div className="hotel-desc-card">
                        <div className="hotel-desc-card-fact-container">
                            <p className="hotel-desc-card-fact-title">fact</p>
                            <p className="hotel-desc-card-fact-text">
                                {hotelData?.tip}
                            </p>
                        </div>
                    </div>
                    <div
                        className="hotel-desc-card"
                        style={{
                            backgroundImage: `url(${hotelData?.pictures[2].url})`,
                        }}
                    ></div>
                    <div className="hotel-desc-card">
                        <div className="hotel-desc-card-info-container">
                            <div className="hotel-desc-card-info-middle">
                                <p className="hotel-desc-card-info-middle-title">
                                    style
                                </p>
                                <p className="hotel-desc-card-info-middle-text">
                                    {hotelData?.style}
                                </p>
                            </div>
                            <div className="hotel-desc-card-info-middle">
                                <p className="hotel-desc-card-info-middle-title">
                                    Accomodation
                                </p>
                                <p className="hotel-desc-card-info-middle-text">
                                    {hotelData?.accomodation}
                                </p>
                            </div>
                            <div className="hotel-desc-card-info-middle">
                                <p className="hotel-desc-card-info-middle-title">
                                    Best Part
                                </p>
                                <p className="hotel-desc-card-info-middle-text">
                                    {hotelData?.best_part}
                                </p>
                            </div>
                            <div className="hotel-desc-card-info-bottom">
                                <p className="hotel-desc-card-info-bottom-title">
                                    Location
                                </p>
                                <p className="hotel-desc-card-info-bottom-text">
                                    {hotelData?.location}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="hotel-desc-card"
                        style={{
                            backgroundImage: `url(${hotelData?.pictures[3].url})`,
                        }}
                    ></div>
                </div>
            ) : (
                <div className="hotel-desc-card-container">
                    <div className="hotel-desc-card-overlay">
                        {firstLetterSizeIncrease(
                            hotelData?.name ? hotelData.name : "Loading..."
                        )}
                    </div>
                    <Slider {...settings} className="hotel-desc-slider">
                        <div className="hotel-desc-card">
                            <img src={`${hotelData?.pictures[0].url}`} alt="" />
                        </div>
                        <div className="hotel-desc-card">
                            <img src={`${hotelData?.pictures[1].url}`} alt="" />
                        </div>
                        <div className="hotel-desc-card">
                            <img src={`${hotelData?.pictures[2].url}`} alt="" />
                        </div>
                        <div className="hotel-desc-card">
                            <img src={`${hotelData?.pictures[3].url}`} alt="" />
                        </div>
                    </Slider>

                    <div className="hotel-desc-card">
                        <div className="hotel-desc-card-fact-container">
                            <p className="hotel-desc-card-fact-title">fact</p>
                            <p className="hotel-desc-card-fact-text">
                                {hotelData?.tip}
                            </p>
                        </div>
                    </div>
                    <div className="hotel-desc-card">
                        <div className="hotel-desc-card-info-container">
                            <div className="hotel-desc-card-info-middle">
                                <p className="hotel-desc-card-info-middle-title">
                                    style
                                </p>
                                <p className="hotel-desc-card-info-middle-text">
                                    {hotelData?.style}
                                </p>
                            </div>
                            <div className="hotel-desc-card-info-middle">
                                <p className="hotel-desc-card-info-middle-title">
                                    Accomodation
                                </p>
                                <p className="hotel-desc-card-info-middle-text">
                                    {hotelData?.accomodation}
                                </p>
                            </div>
                            <div className="hotel-desc-card-info-middle">
                                <p className="hotel-desc-card-info-middle-title">
                                    Best Part
                                </p>
                                <p className="hotel-desc-card-info-middle-text">
                                    {hotelData?.best_part}
                                </p>
                            </div>
                            <div className="hotel-desc-card-info-bottom">
                                <p className="hotel-desc-card-info-bottom-title">
                                    Location
                                </p>
                                <p className="hotel-desc-card-info-bottom-text">
                                    {hotelData?.location}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {windowWidth > 750 ? (
                <>
                    <div className="hotel-desc-icons">
                        <div className="icon icon-1">
                            <FaArrowDown
                                className="hotel-desc-download-icon"
                                onMouseLeave={handleTooltipLeave}
                                onMouseEnter={handleTooltip}
                            />
                        </div>
                        <p
                            className="hotel-desc-icon-tooltip-text"
                            style={{ top: `${tooltipSpace}` }}
                        >
                            {tooltipText}
                        </p>
                        <div className="icon icon-2">
                            <FaShareAlt
                                className="hotel-desc-share-icon"
                                onMouseLeave={handleTooltipLeave}
                                onClick={handleShare}
                                onMouseEnter={handleTooltip}
                            />
                        </div>
                        <div className="icon icon-3">
                            <FaQuestion
                                className="hotel-desc-inquire-icon"
                                onMouseLeave={handleTooltipLeave}
                                onClick={() => navigate(`/enquire/${id}`)}
                                onMouseEnter={handleTooltip}
                            />
                        </div>
                        <div className="icon icon-4">
                            <BiGift
                                className="hotel-desc-active-offers-icon"
                                onMouseLeave={handleTooltipLeave}
                                onClick={() =>
                                    navigate(
                                        `/offers/${hotelData.url_param_key}`
                                    )
                                }
                                onMouseEnter={handleTooltip}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="hotel-desc-mobile-icons">
                        <div className="mobile-icon">
                            <FaArrowDown className="hotel-desc-download-icon" />
                            <p>Download</p>
                        </div>
                        <div className="mobile-icon" onClick={handleShare}>
                            <FaShareAlt className="hotel-desc-share-icon" />
                            <p>Share</p>
                        </div>
                        <div
                            className="mobile-icon"
                            onClick={() => navigate(`/enquire/${id}`)}
                        >
                            <FaQuestion className="hotel-desc-inquire-icon" />
                            <p>Enquire</p>
                        </div>
                        <div
                            className="mobile-icon"
                            onClick={() =>
                                navigate(`/offers/${hotelData.url_param_key}`)
                            }
                        >
                            <BiGift className="hotel-desc-active-icon" />
                            <p>Active offers</p>
                        </div>
                    </div>
                </>
            )}
            {showShare && (
                <ShareModal SetShowShare={setShowShare} hotel={hotelData} />
            )}
        </div>
    );
}

export default HotelDesc;
