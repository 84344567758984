import React from 'react'
import styled from 'styled-components'
import { FaWhatsapp } from 'react-icons/fa';
import { AiOutlineInstagram } from 'react-icons/ai';
import { RiLinkedinFill } from "react-icons/ri"
const Social = () => {
    return (
        <Socialhandles>
            <a href="https://wa.me/+919711983158" target="_blank" rel="noreferrer" aria-label="whatsapp"><FaWhatsapp className="icons" /></a>
            <a href="https://www.instagram.com/luxurylivingshow/" target="_blank" rel="noreferrer" aria-label="instagram"><AiOutlineInstagram className="icons" /></a>
            <a href="https://www.linkedin.com/company/luxury-living-india/" target="_blank" rel="noreferrer" aria-label="linkedin"><RiLinkedinFill className="icons" /></a>
        </Socialhandles>
    )
}

const Socialhandles = styled.div`
height: 130px;
width: 60px;
position: fixed;
left: 30px;
bottom:10px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-evenly;
cursor:pointer;
.icons{
    font-size: 25px;
    color: white;
}
@media (max-width:1200px){
    position: fixed;
    left:-15px;
    top: 45%;
    display:none;
    .icons{
        font-size: 17px;
    }

}
@media (max-width:480px){
    position: fixed;
    left:35%;
    top:85%;
    flex-direction:row;
    width:30%;
    .icons{
        font-size: 17px;
    }

}
`
export default Social
