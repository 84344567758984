import axios from "axios";
import { useEffect, useRef } from "react";
import { FaRegPaperPlane } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";

// Style
import "./FeedbackForm.scss";

// Local Imports
import Header from "../../components/UI/Header/Header";
import Social from "../../components/UI/Social/Social";
import { appUrl } from "../../config/appUrl";

function FeedbackForm() {
    const inputNameRef = useRef();
    const inputCompanyRef = useRef();
    const inputEmailRef = useRef();
    const inputPhoneRef = useRef();
    const messageRef = useRef();

    async function handleForm(event) {
        event.preventDefault();

        const target = event.target;

        const testimony = {
            name: target[0].value,
            companyName: target[1].value,
            email: target[2].value,
            phone: target[3].value,
            feedbackMessage: target[4].value,
        };

        const options = {
            url: `${appUrl.url}/testimonial/create?key=${appUrl.key}`,
            method: "POST",
            headers: {},
            data: testimony,
        };

        axios(options).then(toast.success("Added Successfully"));

        inputNameRef.current.value = "";
        inputCompanyRef.current.value = "";
        inputEmailRef.current.value = "";
        inputPhoneRef.current.value = "";
        messageRef.current.value = "";
    }

    useEffect(() => {
        document.title = "Luxury Living - Feedback";
    });

    return (
        <div className="feedback-form-container">
            <Header />
            <Social />
            <ToastContainer style={{ fontSize: "2rem" }} />
            <form className="feedback-form-tag" onSubmit={handleForm}>
                <div className="feedback-form-title">
                    Tell Us how you feel <br /> how you really really feel?
                </div>
                <div className="feedback-form">
                    <div className="feedback-form-input">
                        <div className="feedback-form-input-container">
                            <input
                                ref={inputNameRef}
                                name="name"
                                type="text"
                                placeholder="First & Last Name *"
                                required
                            />
                            <input
                                ref={inputCompanyRef}
                                name="company"
                                type="text"
                                placeholder="Company Name *"
                                required
                            />
                            <input
                                ref={inputEmailRef}
                                name="email"
                                type="email"
                                placeholder="E-mail *"
                                required
                            />
                            <input
                                className="feedback-form-input-message"
                                ref={inputPhoneRef}
                                name="phone"
                                type="number"
                                placeholder="Phone *"
                                required
                            />
                        </div>
                    </div>
                    <div className="feedback-form-message">
                        <div className="feedback-form-message-container">
                            <label
                                htmlFor="lxreview"
                                className="feedback-form-message-title"
                            >
                                How was your experience of working with us?
                            </label>
                            <textarea
                                ref={messageRef}
                                className="feedback-form-message-text"
                                name="lxreview"
                                id="lxreview"
                                placeholder="Type here..."
                                required
                            ></textarea>
                        </div>
                    </div>
                    <button className="feedback-form-submit-btn">
                        <FaRegPaperPlane className="feedback-form-submit-icon" />
                    </button>
                </div>
            </form>
        </div>
    );
}

export default FeedbackForm;
