import React, { useState, useEffect } from "react";
import Slider from "react-slick";

// Style
import "./AboutUs.scss";

// Local Imports
import imageOne from "../../images/Gaurav-Sharma.png";
import imageTwo from "../../images/Amit-Chawla.jpeg";
import imageThree from "../../images/PP-Singh.jpeg";
import Header from "../../components/UI/Header/Header";
import Social from "../../components/UI/Social/Social";
import AboutUsCard from "../../components/AboutUsCard/AboutUsCard";

function AboutUs() {
    useEffect(() => {
        document.title = "Luxury Living - About Us";
    }, []);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    // active card status [0, 0, 0] = means all cards are deactive and [0, 1, 0] means middle is active
    const [activeCardStatus, setActiveCardStatus] = useState([2, 2, 2]);

    function activeCardHandler(data) {
        setActiveCardStatus(data);
    }

    return (
        <div className="about-us">
            <Header />
            <div className="about-us-main">
                <AboutUsCard
                    name="Gaurav Sharma"
                    activeCard={activeCardStatus}
                    activeCardHandler={activeCardHandler}
                    cardNumber="0"
                    profile={imageOne}
                    aboutDesc="Many Many centuries ago, once there was a man whose head, when shaved clean, was shinier than the stars & his name struck terror in the hearts...wait..thats way too dramatic an intro for this site. 
                    <br /><br />
                    Let's keep is simple...shall we?
                    <br /><br />
                    Gaurav is Luxury Living's co - founder & director. Since before he can remember, his greatest ability has been to 'think faster than he talks'. And talks he does. He has forever been in sales & into setting & scaling up new businesses. 
                    <br /><br />
                    On a side note, he likes discussing physics, rock music & writing quirky, witty & borderline whacky promotional messages for Luxury Living's product line!"
                />
                <AboutUsCard
                    name="Amit Chawla"
                    activeCard={activeCardStatus}
                    activeCardHandler={activeCardHandler}
                    cardNumber="1"
                    profile={imageTwo}
                    aboutDesc="What happens when you have an engaging discussion for an hour about any topic under the sun & also get beaten in a game of table tennis - twice!
                    <br /><br />
                    Well you most likely spend that hour with Amit, Luxury Living's co-founder & director.
                    <br /><br />
                    Amit loves decoding balance sheets for breakfast and a game of basketball anytime, an uncrowned authority on wellness therapies & one of the finest travel experience curators!"
                />
                <AboutUsCard
                    name="PP Singh"
                    activeCard={activeCardStatus}
                    activeCardHandler={activeCardHandler}
                    cardNumber="2"
                    profile={imageThree}
                    aboutDesc="Mr. Singh is an engineer by profession with over 45 years of experience in managing sales, distribution and financial compliance for multiple self-owned & other companies. An investor by choice. A humble man by nature."
                />
            </div>
            <div className="about-us-secondary">
                <div className="about-us-slider">
                    {/* <div className="about-us-slider-overlay">
                        <p>luxury living</p>
                    </div> */}
                    <Slider className="about-us-slider-main" {...settings}>
                        <div className="about-us-team team-one">
                            luxury living
                        </div>
                        <div className="about-us-team team-two">
                            luxury living
                        </div>
                        {/* <div className="about-us-team team-three"></div> */}
                    </Slider>
                </div>
            </div>
            <Social />
        </div>
    );
}

export default AboutUs;
