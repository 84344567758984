import { AiOutlineLoading3Quarters } from "react-icons/ai";

// Styles
import styles from "./CircleLoading.module.scss";

const CircleLoading = ({ size }) => {
    return (
        <div className={styles.loading_container}>
            <AiOutlineLoading3Quarters size={size} />
        </div>
    );
};

export default CircleLoading;
