import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Local Imports
import Home from "./pages/home/Home";
import Feedback from "./pages/FeedbackForm/FeedbackForm";
import ContactUs from "./pages/Contactus/ContactUs";
import Agents from "./pages/SignUpAgents/Agents";
import HotelPatners from "./pages/HotelPartners/HotelPartners";
import Portfolio from "./pages/Portfolio/Portfolio";
import HotelDesc from "./pages/HotelsDesc/HotelDesc";
import AboutUs from "./pages/AboutUs/AboutUs";
import ClientsForm from "./pages/ClientsPreferForm/ClientsForm";
import HotelsCollabs from "./pages/HotelCollabs/HotelsCollabs";
import Offers from "./pages/Offers/Offers";
import Enquiry from "./components/Enquiry/Enquiry";
import ErrorPage from "./pages/NotFound/NotFound";
import HotelList from "./pages/HotelListPage/HotelListPage";

const App = () => {
    return (
        <div style={{ overflow: "hidden" }}>
            <Router>
                <Routes>
                    <Route path="/*">
                        {/* Home Page */}
                        <Route index element={<Home />} />

                        {/* Feedback Form Page */}
                        <Route path="feedback" element={<Feedback />} />

                        {/* Contact Us Page */}
                        <Route path="contactus" element={<ContactUs />} />

                        {/* //TODO: Agents (Sign / Login)  Page ( Not Yet Developed ) */}
                        <Route path="sign-up-agents" element={<Agents />} />

                        {/* //TODO:  Hotel Partners ( Collaborative Info ) Page ( Not Yet Developed ) */}
                        <Route
                            path="hotel-partners"
                            element={<HotelPatners />}
                        />

                        {/* About us Page */}
                        <Route path="aboutus" element={<AboutUs />} />

                        {/* Hotel Collaboration ( New Hotels ) Page */}
                        <Route
                            path="hotel-collabs"
                            element={<HotelsCollabs />}
                        />

                        {/* Offers Page */}
                        <Route path="offers" element={<Offers />} />
                        <Route path="offers/:searchKey" element={<Offers />} />

                        {/* //TODO: Client Preference Page ( Not Yet Developed ) */}
                        <Route
                            path="luxury-living-clients-preference"
                            element={<ClientsForm />}
                        />

                        {/* Portfolio Page */}
                        <Route
                            path="luxury-living-portfolio/"
                            element={<Portfolio />}
                        ></Route>
                        <Route
                            path="luxury-living-portfolio/:id/"
                            element={<HotelDesc />}
                        ></Route>

                        {/* Hotels' List Page */}
                        <Route path="hotels-list" element={<HotelList />} />

                        {/* Hotel Enquiry Page */}
                        <Route path="enquire/:id" element={<Enquiry />} />

                        {/* Error Page */}
                        <Route path="*" element={<ErrorPage />} />
                    </Route>
                </Routes>
            </Router>
        </div>
    );
};

export default App;
