import React from 'react'
import { LoadingOutlined } from '@ant-design/icons';
const Loading = (props) => {
  return (
    <div
      style={{
        width: '100vw',
        height: props.height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '20rem',
        fontFamily: 'Shoreline',
        position: 'fixed',
        top: props.top,
        left: "0",
      }}
    ><LoadingOutlined /></div>
  )
}

export default Loading