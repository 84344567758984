import { useState } from "react";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";

import "./OffersCard.scss";

function OffersCard(props) {
    const [isCardAnimated, setIsCardAnimated] = useState(false);
    const [cardAnimate, setCardAnimate] = useState("");

    function randomColor(field) {
        let randomNumber = Math.floor(Math.random() * field.length);
        let charName = field[randomNumber];
        let result = [];
        for (let i = 0; i < field.length; i++) {
            if (i === randomNumber) {
                if (field[i] === " ") {
                    result.push(<span key={i}>{field[i]}</span>)
                    result.push(<span key={i+1} style={{ color: "gold"}}>{field[i+1]}</span>)
                    i++;
                } else {
                    result.push(
                        <span key={i} style={{ color: "gold" }}>
                            {charName}
                        </span>
                    );
                }
            } else {
                result.push(<span key={i}>{field[i]}</span>);
            }
        }
        return <h3>{result}</h3>;
    }

    function handleAnimate() {
        setIsCardAnimated(!isCardAnimated);
        cardAnimate === "offer-card-desc-container-active"
            ? setCardAnimate("")
            : setCardAnimate("offer-card-desc-container-active");
    }

    return (
        <div className="offer-card">
            <div className="offer-card-name-container">
                <p className="offer-card-name">
                    {randomColor(props.offerName)}
                </p>
            </div>
            <div
                className="offer-card-image-container"
                style={{
                    backgroundImage: `linear-gradient(to right bottom, rgba(5, 5, 15, .6), rgba(5, 5, 15, .6)), url(${props.hotelImage})`,
                }}
            >
                <p>{props.hotelHeading}</p>
            </div>
            <div
                className={`offer-card-desc-container ${cardAnimate}`}
            >
                <div dangerouslySetInnerHTML={{ __html: props.offerDetail }}></div>

                <p className="offer-card-date-tr">Travel Period : {props.startDate} <span style={{ fontWeight: "bold"}}>To</span> {props.endDate}</p>
                <p className="offer-card-date-bk">Booking Period : <span style={{ fontWeight: "bold"}}>Upto</span> {props.bookEndDate}</p>
            </div>
            <div className="offer-card-animate-button-container">
                <p
                    className="offer-card-animate-button"
                    onClick={handleAnimate}
                >
                    {isCardAnimated ? "Read Less" : "Read More"}
                    {isCardAnimated ? (
                        <AiOutlineArrowUp className="offer-card-animate-icon" />
                    ) : (
                        <AiOutlineArrowDown className="offer-card-animate-icon" />
                    )}
                </p>
            </div>
        </div>
    );
}

export default OffersCard;

// import React, { useState, useEffect } from "react";
// import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
// import Aos from "aos";
// import "aos/dist/aos.css";
// import "./styles.scss";

// function randomColor(field) {
//     let randomNumber = Math.floor(Math.random() * field.length);
//     let charName = field[randomNumber];
//     let result = [];
//     for (let i = 0; i < field.length; i++) {
//         if (i === randomNumber) {
//             result.push(
//                 <span key={i} style={{ color: "gold" }}>
//                     {charName}
//                 </span>
//             );
//         } else {
//             result.push(<span key={i}>{field[i]}</span>);
//         }
//     }
//     return <h3>{result}</h3>;
// }

// const OffersCard = (props) => {
//     const [readAnimate, setReadAnimate] = useState(true);

//     function handleAnimate() {
//         setReadAnimate(!readAnimate);
//     }

//     let windowWidth = 0;
//     const { innerWidth: width } = window;
//     windowWidth = width;

//     const {
//         offerName,
//         offerDetail,
//         startDates,
//         endDates,
//         bookStartDates,
//         bookEndDates,
//         hotelImage,
//         hotelHeading,
//     } = props; // setDetails also was included

//     function reverseString(str) {
//         if (str === undefined) {
//         } else {
//             let splitString = str.split("-");
//             let reversed = splitString.reverse();
//             let resultString = reversed.join("-");
//             return resultString;
//         }
//     }

//     useEffect(() => {
//         Aos.init({ duration: 1000 });
//     }, []);

//     // breaking offer name into two fields
//     let firstField = "";
//     let secondField = "";
//     const fieldArray = offerName.split(" ");
//     firstField = fieldArray[0];
//     secondField = fieldArray[1];

//     return (
//         <div className="offer-card" data-aos="fade">
//             <div className="offer-card-section offer-card-middle">
//                 <div className="offer-name-container">
//                     <div className="offer-name-container-first">
//                         {randomColor(firstField)}
//                     </div>
//                     <div className="offer-name-container-second">
//                         <h3>{secondField}</h3>
//                     </div>
//                 </div>
//             </div>

//             {/*
//                 for desktop
//             */}

//             {windowWidth > 1048 ? (
//                 <>
//                     <div className="offer-card-section offer-card-first">
//                         <div
//                             className="offer-card-hotel-name"
//                             style={{
//                                 backgroundImage: `linear-gradient(to right bottom, rgba(5, 5, 15, .6), rgba(5, 5, 15, .6)), url(${hotelImage})`,
//                                 backgroundSize: "cover",
//                             }}
//                         >
//                             <p>{hotelHeading}</p>
//                         </div>
//                         <div className="offer-card-section-dates">
//                             <div
//                                 className="offer-card-section-desc-inner"
//                                 dangerouslySetInnerHTML={{
//                                     __html: offerDetail,
//                                 }}
//                             ></div>
//                             <div className="dates date-travel">
//                                 {/*<span>Travel period</span>{reverseString(startDates)} <span>to</span> {reverseString(endDates)}*/}
//                                 <p className="dates-heading">Travel Period</p>
//                                 <p className="dates-info">
//                                     {reverseString(startDates)} <span>To</span>{" "}
//                                     {reverseString(endDates)}
//                                 </p>
//                             </div>
//                             <div className="dates date-booking">
//                                 <p className="dates-heading-second">
//                                     Booking Period
//                                 </p>
//                                 <p className="dates-info">
//                                     <span>
//                                         {bookStartDates.slice(0, 4) === "2001"
//                                             ? "Upto "
//                                             : ""}
//                                     </span>
//                                     {bookStartDates.slice(0, 4) === "2001"
//                                         ? ""
//                                         : reverseString(bookStartDates)}{" "}
//                                     <span>
//                                         {bookStartDates.slice(0, 4) === "2001"
//                                             ? ""
//                                             : "To"}
//                                     </span>{" "}
//                                     {reverseString(bookEndDates)}
//                                 </p>
//                                 {/*<span>Booking period: </span>{bookStartDates.slice(0,4) === "2001" ? "" : reverseString(bookStartDates)} <span>{bookStartDates.slice(0,4) === "2001" ? "Upto" : "to"}</span> {reverseString(bookEndDates)}*/}
//                             </div>
//                         </div>
//                     </div>
//                     <div className="offer-card-section offer-card-end">
//                         <div className="offer-card-section-desc">
//                             {windowWidth > 1048 ? (
//                                 <div
//                                     className="offer-card-section-desc-inner"
//                                     dangerouslySetInnerHTML={{
//                                         __html: offerDetail,
//                                     }}
//                                 ></div>
//                             ) : (
//                                 readAnimate && (
//                                     <div
//                                         className="offer-card-section-desc-inner"
//                                         dangerouslySetInnerHTML={{
//                                             __html: offerDetail,
//                                         }}
//                                     ></div>
//                                 )
//                             )}
//                         </div>
//                     </div>
//                 </>
//             ) : (
//                 <>
//                     <div className="offer-card-section offer-card-first">
//                         <div
//                             className="offer-card-hotel-name"
//                             style={{
//                                 backgroundImage: `linear-gradient(to right bottom, rgba(5, 5, 15, .6), rgba(5, 5, 15, .6)), url(${hotelImage})`,
//                                 backgroundSize: "cover",
//                             }}
//                         >
//                             <p>{hotelHeading}</p>
//                         </div>
//                     </div>
//                     <div className="offer-card-section offer-card-end">
//                         <div className="offer-card-section-desc">
//                             {readAnimate ? (
//                                 <div
//                                     className="offer-card-section-description"
//                                     style={{
//                                         overflow: "hidden",
//                                         height: "40px",
//                                     }}
//                                 >
//                                     <div
//                                         className="offer-card-section-desc-inner"
//                                         dangerouslySetInnerHTML={{
//                                             __html: offerDetail,
//                                         }}
//                                     ></div>
//                                     <div className="offer-card-section-dates">
//                                         <div className="dates date-travel">
//                                             {/*<span>Travel period</span>{reverseString(startDates)} <span>to</span> {reverseString(endDates)}*/}
//                                             <p className="dates-heading">
//                                                 Travel Period
//                                             </p>
//                                             <p className="dates-info">
//                                                 {reverseString(startDates)}{" "}
//                                                 <span>To </span>{" "}
//                                                 {reverseString(endDates)}
//                                             </p>
//                                         </div>
//                                         <div className="dates date-booking">
//                                             <p className="dates-heading-second">
//                                                 Booking Period
//                                             </p>
//                                             <p className="dates-info">
//                                                 <span>
//                                                     {bookStartDates.slice(
//                                                         0,
//                                                         4
//                                                     ) === "2001"
//                                                         ? "Upto "
//                                                         : ""}
//                                                 </span>
//                                                 {bookStartDates.slice(0, 4) ===
//                                                 "2001"
//                                                     ? ""
//                                                     : reverseString(
//                                                           bookStartDates
//                                                       )}{" "}
//                                                 <span>
//                                                     {bookStartDates.slice(
//                                                         0,
//                                                         4
//                                                     ) === "2001"
//                                                         ? ""
//                                                         : "To"}
//                                                 </span>{" "}
//                                                 {reverseString(bookEndDates)}
//                                             </p>
//                                             {/*<span>Booking period: </span>{bookStartDates.slice(0,4) === "2001" ? "" : reverseString(bookStartDates)} <span>{bookStartDates.slice(0,4) === "2001" ? "Upto" : "to"}</span> {reverseString(bookEndDates)}*/}
//                                         </div>
//                                     </div>
//                                 </div>
//                             ) : (
//                                 <div className="offer-card-section-description">
//                                     <div
//                                         className="offer-card-section-desc-inner"
//                                         dangerouslySetInnerHTML={{
//                                             __html: offerDetail,
//                                         }}
//                                     ></div>
//                                     <div className="offer-card-section-dates">
//                                         <div className="dates date-travel">
//                                             {/*<span>Travel period</span>{reverseString(startDates)} <span>to</span> {reverseString(endDates)}*/}
//                                             <p className="dates-heading">
//                                                 Travel Period
//                                             </p>
//                                             <p className="dates-info">
//                                                 {reverseString(startDates)}{" "}
//                                                 <span>To </span>{" "}
//                                                 {reverseString(endDates)}
//                                             </p>
//                                         </div>
//                                         <div className="dates date-booking">
//                                             <p className="dates-heading-second">
//                                                 Booking Period
//                                             </p>
//                                             <p className="dates-info">
//                                                 <span>
//                                                     {bookStartDates.slice(
//                                                         0,
//                                                         4
//                                                     ) === "2001"
//                                                         ? "Upto "
//                                                         : ""}
//                                                 </span>
//                                                 {bookStartDates.slice(0, 4) ===
//                                                 "2001"
//                                                     ? ""
//                                                     : reverseString(
//                                                           bookStartDates
//                                                       )}{" "}
//                                                 <span>
//                                                     {bookStartDates.slice(
//                                                         0,
//                                                         4
//                                                     ) === "2001"
//                                                         ? ""
//                                                         : "To"}
//                                                 </span>{" "}
//                                                 {reverseString(bookEndDates)}
//                                             </p>
//                                             {/*<span>Booking period: </span>{bookStartDates.slice(0,4) === "2001" ? "" : reverseString(bookStartDates)} <span>{bookStartDates.slice(0,4) === "2001" ? "Upto" : "to"}</span> {reverseString(bookEndDates)}*/}
//                                         </div>
//                                     </div>
//                                 </div>
//                             )}
//                             <p
//                                 className="offer-read-btn"
//                                 onClick={handleAnimate}
//                             >
//                                 {readAnimate ? "Read More" : "Read Less"}
//                                 <span>
//                                     {readAnimate ? (
//                                         <AiOutlineArrowDown />
//                                     ) : (
//                                         <AiOutlineArrowUp />
//                                     )}
//                                 </span>
//                             </p>
//                         </div>
//                     </div>
//                 </>
//             )}
//         </div>
//     );
// };

// export default OffersCard;

// //     return (
// //         <div
// //             className="offers_card"
// //             style={{
// //                 animationName: animate
// //                     ? "offer_card_length"
// //                     : "offer_card_decrease",
// //                 animationDuration: "1s",
// //                 height: "fit-content",

// //             }}
// //         >
// //             <div className="hotel_card_container">
// //                 <div className="hotel_name">
// //                     {/* <div className="img">
// //                         <img src={hotelImage ? hotelImage : " "} alt="" />
// //                     </div> */}
// //                     <div className="hotel_">
// //                         <h3>{offerName}</h3>
// //                     </div>
// //                 </div>
// //                 <div className="offer_details">
// //                     <h4 className="offer_name">{hotelHeading}</h4>
// //                     <div className="dates_container">
// //                         <p className="dates date-travel">
// //                             <span>Travel period: </span>{reverseString(startDates)} <span>to</span> {reverseString(endDates)}
// //                         </p>
// //                     </div>
// //                     <div className="dates_container">
// //                         <p className="dates date-booking">
// //                             {console.log(bookStartDates.slice(0,4))}
// //                             <span>Booking period: </span>{bookStartDates.slice(0,4) === "2001" ? "" : reverseString(bookStartDates)} <span>{bookStartDates.slice(0,4) === "2001" ? "Upto" : "to"}</span> {reverseString(bookEndDates)}
// //                         </p>
// //                     </div>
// //                     <hr />
// //                     <div
// //                         className={`offer_detail ${animate ? webkitAnimation : ""}`}
// //                     >
// //                         {<div dangerouslySetInnerHTML={{ __html: offerDetail}}></div>}
// //                     </div>
// //                     {animate && <hr />}

// //                     <h6
// //                         className={`room_category ${animate ? webkitAnimation : ""}`}
// //                     >
// //                         Room Categories : <span>{room_cate}{" "}</span>
// //                     </h6>
// //                     <button className="more_details" onClick={handleClick}>
// //                         {animate ? "Read Less" : "Read More"}
// //                         {animate ? (
// //                             <AiOutlineArrowUp className="arrow" />
// //                         ) : (
// //                             <AiOutlineArrowDown className="arrow" />
// //                         )}
// //                     </button>
// //                 </div>
// //             </div>
// //         </div>
// //     );
// // };
