import "./StarWarsIntro.scss";

const StarWarsIntro = () => {
    return (
        <section className="st-intro-section">
            <div className="intro-main-container">
                <div className="wrapper">
                    <div className="scroll-text">
                        <div>
                            <div className="intro-number">
                                <p>1</p>
                            </div>
                            We are a B2B travel company and we only work with
                            high-end / luxury hotels & resorts. We take pride in
                            being the only one of our kind in the Indian
                            Subcontinent.
                            <br />
                            <br /> We are completely offline & we like to keep
                            it that way.
                            <br />
                            <br /> Travel Agents approach us with their requests
                            & we assist them in selecting & booking the right
                            luxury resort for their clients.
                            <br />
                            <br /> Not many B2B companies are this bespoke &
                            consultative in their approach.
                            <br />
                            <br />
                        </div>
                        <div>
                            <div className="intro-number">
                                <p>2</p>
                            </div>
                            We always book direct with our partner hotels. No
                            shortcuts. No bypass. If a hotel is not a part of
                            our portfolio, then we don't promote it.
                            <br />
                            <br /> Snooty? Nope. being focused is more our
                            thing!
                            <br />
                            <br />
                        </div>
                        <div>
                            <div className="intro-number">
                                <p>3</p>
                            </div>
                            Apart from servicing some of the established luxury
                            travel companies, we also support & encourage new
                            travel companies who wish to enter into the luxury
                            travel space. We coach them, train them & empower
                            them to up-sell.
                            <br />
                            <br />
                            Some like to call us the “Robinhood” of Travel...&
                            We don’t really mind that!
                        </div>
                    </div>
                </div>
            </div>
            <div className="st-intro-separator"></div>
            <div className="intro-secondary-container">
                <p className="text">
                    <span className="logo">luxury living</span>- 'THE
                    GRAVITATIONAL CONSTANT OF LUXURY TRAVEL' -
                    <em>since 2014</em>
                </p>
            </div>
        </section>
    );
};

export default StarWarsIntro;
