import { useState } from "react";

const QueryInput = (props) => {
    const [childCount, setChildCount] = useState(0);

    function increaseCounter() {
        if (childCount < 100) {
            setChildCount(childCount + 1);
            props.setChildActive();
            let array = [];
            for (let i = 0; i < childCount; i++) {
                array.push(0);
            }
            array.push(0);
            props.setChildArray(array);
        }
    }

    function decreaseCounter() {
        if (childCount > 0) {
            setChildCount(childCount - 1);
            let array = [];
            for (let i = 0; i < childCount; i++) {
                array.push(0);
            }
            array.pop();
            props.setChildArray(array);
        }
        if (childCount === 1) {
            props.setChildNotActive();
        }
    }

    return (
        <div className="enquiry-form-inputs">
            <p className="enquiry-form-input-title">Query details</p>
            <div className="enquiry-form-inputs-main">
                <div className="input-box">
                    <label
                        className="enquiry-input-label"
                        htmlFor="check_in_date"
                    >
                        Check in date :
                    </label>
                    <input
                        className="enquiry-input"
                        id="check_in_date"
                        required
                        type="date"
                        style={{
                            colorScheme: "dark",
                        }}
                    />
                </div>
                <div className="input-box">
                    <label className="enquiry-input-label" htmlFor="nights">
                        Number of nights :
                    </label>
                    <input
                        className="enquiry-input"
                        id="nights"
                        required
                        type="number"
                    ></input>
                </div>
                <div className="input-box">
                    <label className="enquiry-input-label" htmlFor="adults">
                        Adults :
                    </label>
                    <input
                        className="enquiry-input"
                        id="adults"
                        required
                        type="number"
                    ></input>
                </div>
                <div className="input-box" style={{ position: "relative" }}>
                    <label className="enquiry-input-label" htmlFor="children">
                        children :
                    </label>
                    <input
                        className="enquiry-input children-input"
                        id="Children"
                        type="number"
                        readOnly
                        value={childCount}
                    />
                    <div className="children-input_range_fix">
                        <div
                            className="children-counter-button"
                            onClick={increaseCounter}
                        >
                            +
                        </div>
                        <div
                            className="children-counter-button"
                            onClick={decreaseCounter}
                        >
                            -
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QueryInput;
