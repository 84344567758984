import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: null,
    valueTwo: null,
};

const searchSlice = createSlice({
    name: "searchTerm",
    initialState,
    reducers: {
        searchAdd: (state, action) => {
            state.value = action.payload;
            state.valueTwo = action.payload;
        },
    },
});

export const { searchAdd } = searchSlice.actions

export default searchSlice.reducer