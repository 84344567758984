import { useState, useEffect } from "react";
import City from "../City/City";
import "./Country.scss";

function Country({ countryName, hotelList, allHotels }) {
    const [showHotels, setShowHotels] = useState(false);
    const [cities, setCities] = useState();

    function handleCountryClick() {
        setShowHotels(!showHotels);
    }

    useEffect(() => {
        const unique = (value, index, self) => {
            return self.indexOf(value) === index;
        };

        const allCities = hotelList.filter(
            (item) => item.country === countryName
        );

        const cityNames = allCities.map((city) => city.city).sort();
        const uniqueCities = cityNames.filter(unique);
        setCities(uniqueCities);
    }, [countryName, hotelList]);

    return (
        <div className="country-name-box">
            <p
                className={`country-name ${showHotels && "country-active"}`}
                onClick={handleCountryClick}
            >
                {countryName}
            </p>
            {showHotels && <City cities={cities} allHotels={allHotels} />}
        </div>
    );
}

export default Country;
