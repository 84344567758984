const AgentInput = () => {
    return (
        <div className="enquiry-form-inputs">
            <p className="enquiry-form-input-title">Your details</p>
            <div className="enquiry-form-inputs-main">
                <div className="input-box">
                    <label className="enquiry-input-label" htmlFor="agent_name">
                        Name :
                    </label>
                    <input
                        className="enquiry-input"
                        id="agent_name"
                        type="text"
                        autoComplete="off"
                        required
                    ></input>
                </div>
                <div className="input-box">
                    <label
                        className="enquiry-input-label"
                        htmlFor="agent_agency"
                    >
                        Agency Name :
                    </label>
                    <input
                        className="enquiry-input"
                        id="agent_agency"
                        type="text"
                        autoComplete="off"
                        required
                    ></input>
                </div>
                <div className="input-box">
                    <label
                        className="enquiry-input-label"
                        htmlFor="agent_email"
                    >
                        Email address :
                    </label>
                    <input
                        className="enquiry-input"
                        id="agent_email"
                        type="email"
                        autoComplete="off"
                        required
                    ></input>
                </div>
                <div className="input-box">
                    <label
                        className="enquiry-input-label"
                        htmlFor="agent_contact"
                    >
                        Contact No. :
                    </label>
                    <input
                        className="enquiry-input"
                        id="agent_contact"
                        type="number"
                    ></input>
                </div>
            </div>
        </div>
    );
};

export default AgentInput;
