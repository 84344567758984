import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GiftOutlined } from "@ant-design/icons";
import { FaHandshake } from "react-icons/fa";
import { RiFileList2Line } from "react-icons/ri";

// Styles
import "./style.scss";

// Local Imports
import Navbar from "../../../components/UI/Navbar/Navbar";
import Ham from "../Hamburger/Ham";
import Search from "../Search/Search";

const Header = () => {
    const navigate = useNavigate();
    const toTop = () => {
        window.scroll({
            top: 0,
            behavior: "auto",
        });
    };

    const [isOpen, setIsOpen] = useState(false);
    const showNavbar = () => {
        setIsOpen(!isOpen);

        // Making background fixed when navbar is open
        if (!isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "visible";
        }
    };

    return (
        <nav className="header">
            <div className="header_wrapper">
                <div className="ham_div" onClick={showNavbar}>
                    <Ham />
                </div>
                <div className="navs_right">
                    <Search />
                    <div
                        className="navs"
                        onClick={() => navigate("/hotels-list")}
                    >
                        <div className="img">
                            {/* <img src="../../images/question1.png" alt="" /> */}
                            <RiFileList2Line
                                style={{
                                    width: "30px",
                                    height: "30px",
                                    color: "#a37826",
                                }}
                            />
                        </div>

                        <div className="link">
                            <span data-text="Hotels' List" className="links">
                                {" "}
                                Hotels'&nbsp;List
                            </span>{" "}
                        </div>
                    </div>
                    <div
                        className="navs"
                        onClick={(e) => {
                            navigate("/hotel-collabs");
                            toTop();
                        }}
                    >
                        <div className="img">
                            {/* <img src="../../images/question1.png" alt="" /> */}
                            <FaHandshake
                                style={{
                                    width: "30px",
                                    height: "30px",
                                    color: "#a37826",
                                }}
                            />
                        </div>

                        <div className="link">
                            <span
                                data-text=" New&nbsp;Hotel Collaborations"
                                className="links"
                            >
                                {" "}
                                New&nbsp;Hotel&nbsp;Collaborations
                            </span>{" "}
                        </div>
                    </div>

                    <div
                        className="navs "
                        onClick={(e) => {
                            navigate("/offers");
                            toTop();
                        }}
                    >
                        <div className="img">
                            <GiftOutlined className="icon" />
                        </div>
                        <div className="link">
                            <span
                                data-text="Irresistible Offers"
                                className="links"
                            >
                                Irresistible&nbsp;Offers{" "}
                            </span>{" "}
                        </div>
                    </div>
                </div>

                {isOpen && <Navbar navRemove={showNavbar} />}
            </div>
        </nav>
    );
};

export default Header;
