import { BiSearch } from "react-icons/bi";
import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Styles
import styles from "./Search.module.scss";
import { useEffect } from "react";
import { appUrl } from "../../../config/appUrl";

// Local Imports
import { setHotelListData } from "../../../redux/hotelListReducer";
import { BsXLg } from "react-icons/bs";

//***************************************************************** */ Searched Hotels Component

const SearchedHotel = (props) => {
    const navigate = useNavigate();

    // Function to open the hotel description page in new tab
    function handleClick() {
        if (window.innerWidth <= 500) {
            navigate(`/luxury-living-portfolio/${props.hotelId}`);
        } else {
            window.open(`/luxury-living-portfolio/${props.hotelId}`, "_blank");
        }
    }

    return (
        <div className={styles.hotel} onClick={handleClick}>
            <p>
                <span className={styles.hotel_name}>{props.hotelName}</span>,
                <span className={styles.hotel_destination}>
                    &nbsp;
                    {props.hotelCountry}
                </span>
            </p>
        </div>
    );
};

//***************************************************************** */ Searched Hotels Component

//***************************************************************** */ Main Component

const Search = () => {
    // State
    const [searchedHotels, setSearchedHotels] = useState([]);
    const [searchedText, setSearchedText] = useState("");
    const searchInput = useRef(null);
    const [allHotels, setAllHotels] = useState([]);
    const [searchActive, setSearchActive] = useState(false);

    // React Router
    const route = useParams();

    // Redux
    const allHotelsRedux = useSelector((state) => state.hotelList.hotelList);
    const dispatch = useDispatch();

    useEffect(() => {
        // Checking if the page is reloaded and home page is not visited then get the hotels data to search
        if (allHotelsRedux.length !== 0) {
            setAllHotels(allHotelsRedux);
        } else if (
            allHotelsRedux.length === 0 &&
            route["*"] !== "" &&
            route["*"] !== "hotel-collabs"
        ) {
            async function getData() {
                try {
                    const response = await fetch(
                        `${appUrl.url}/hotels?key=${appUrl.key}`
                    );
                    const data = await response.json();
                    dispatch(setHotelListData(data));
                } catch (err) {
                    console.log(err);
                }
            }
            getData();
        }
    }, [allHotelsRedux, route, dispatch]);

    const handleText = (text) => {
        setSearchedText(text);
        searchHotels(allHotels, text.toLowerCase());
    };

    // Function to add a cursor on search input when hovered on icon
    // function handleFocus() {
    //     searchInput.current.focus();
    // }

    // Function to get the related hotels to the given input
    function searchHotels(arr, text) {
        // list of related hotels
        const searchedHotelList = [];
        // if the input is included in any of the properites of hotel then add it to the related hotels list
        for (let i = 0; i < arr.length; i++) {
            if (
                arr[i].name.toLowerCase().includes(text) ||
                arr[i].country.toLowerCase().includes(text) ||
                arr[i].description1.toLowerCase().includes(text) ||
                arr[i].brand_name.toLowerCase().includes(text) ||
                arr[i].city.toLowerCase().includes(text)
            ) {
                searchedHotelList.push(arr[i]);
            }
        }

        // if input is empty set list to be shown to be empty
        if (text === "") {
            setSearchedHotels([]);
        } else {
            setSearchedHotels(searchedHotelList);
        }
    }

    // Function to activate and de-activate the search component when clicked on search icon
    function handleSearchClick() {
        searchInput.current.focus();
        setSearchActive(!searchActive);
    }

    const hotelListComponent = searchedHotels.map((hotel) => {
        return (
            <SearchedHotel
                hotelName={hotel.name}
                hotelCountry={hotel.country}
                key={hotel.id}
                hotelId={hotel.url_param_key}
            />
        );
    });

    return (
        <div className={styles.search_container}>
            <BiSearch
                size="30px"
                className={styles.search_icon}
                onClick={handleSearchClick}
            />
            <div
                className={`${styles.search_bar_container} ${
                    searchActive && styles.search_bar_container_active
                }`}
                //* Doesn't need these for now! */
            >
                <input
                    placeholder=" Enter a hotel or a city name here.."
                    onChange={(event) => {
                        event.preventDefault();
                        return handleText(event.target.value);
                    }}
                    ref={searchInput}
                />
                <BsXLg
                    size={12}
                    className={styles.cross_icon}
                    onClick={handleSearchClick}
                />
                <div className={styles.searched_hotels}>
                    {
                        searchedHotels.length !== 0 &&
                            hotelListComponent /* <- This is a List Rendered component */
                    }
                    {/* HTML Below will be rendered when nothing is found in search */}
                    {searchedText !== "" && searchedHotels.length === 0 && (
                        <div className={styles.not_found}>
                            <p className={styles.not_found__text}>
                                Sorry, we haven't added this in our portfolio
                                yet.
                            </p>
                            <p className={styles.not_found__graphic}>:')</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Search;

//***************************************************************** */ Main Component
