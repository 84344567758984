import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

// Style
import "./Enquiry.scss";

// Local Imports
import { appUrl } from "../../config/appUrl";
import AgentInput from "./AgentInput/AgentInput";
import QueryInput from "./QueryInput/QueryInput";

function Enquiry() {
    const navigate = useNavigate();
    const [hotelData, setHotelData] = useState();
    const hotelId = useParams();

    const [isChildAge, setIsChildAge] = useState(true);
    const [childNumber, setChildNumber] = useState([]);
    const [querySent, setQuerySent] = useState(false);

    const submitRef = useRef();

    useEffect(() => {
        document.title = "Luxury Living - Enquiry";
        const getData = async () => {
            const data = await axios(
                `${appUrl.url}/hotel?pk=${hotelId.id}&&key=9d3480fc-49c4-4427-b19e-3f70d753656d`
            );
            setHotelData(data);
        };
        getData();
    }, [hotelId.id]);

    // on Cancellation of form
    function handleCancel(event) {
        event.preventDefault();
        navigate(-1);
    }

    // Function to set the childActiveComponent if the childcount is greater than 0
    function setChildActiveFunction() {
        setIsChildAge(false);
    }

    // Function to remove the childActiveComponent if the childcount is less than 0
    function setChildNotActiveFunction() {
        setIsChildAge(true);
    }

    // Function to add new Child on child's age array
    function childNumberHandler(array) {
        setChildNumber(array);
    }

    // On submission of form
    function handleSubmit(event) {
        event.preventDefault();

        // Set the querysent to true so that loading is enabled on button
        setQuerySent(true);

        let children_ages = [];
        for (let i = 0; i < event.target[7].value; i++) {
            children_ages.push(+event.target[9 + i].value);
        }

        const formData = {
            hotel_details: {
                id: hotelId.id,
                name: hotelData.data.name,
                country: hotelData.data.country,
                city: hotelData.data.city,
            },
            message: event.target[8].value,
            query_details: {
                check_in_date: event.target[4].value,
                number_of_nights: +event.target[5].value,
                adults: +event.target[6].value,
                children: +event.target[7].value,
                children_ages,
            },
            client_details: {
                name: event.target[0].value,
                agency: event.target[1].value,
                email: event.target[2].value,
                phone: +event.target[3].value,
            },
        };

        console.log(formData);

        const options = {
            url: `${appUrl.url}/enquiry/create?key=${appUrl.key}`,
            method: "POST",
            headers: {},
            data: formData,
        };

        axios(options).then((response) => {
            if (response.data.status === 200) {
                toast.success("Query sent!");
            } else {
                toast.error("Couldn't send, Try again!");
            }

            // Remove loading circle after response
            setQuerySent(false);
            submitRef.current.removeAttribute("disabled", false);
        });

        submitRef.current.setAttribute("disabled", true);
    }

    return (
        <div className="enquiry-container">
            <div className="enquiry-main-container">
                <div className="enquiry-main">
                    <div className="enquiry-main-left">
                        <p className="logo">luxury living</p>
                        <p className="form-title">
                            Input details of your request here... and we’ll get
                            back to you
                        </p>
                        <div className="contacts">
                            <p className="contact-title">Need it now?</p>
                            <p className="contact">
                                <span>Call :</span>
                                <a href="tel:+919899699991"> +91 9899699991,</a>
                                <a href="tel:+919711983158"> +91 9711983158</a>
                            </p>
                        </div>
                    </div>
                    <ToastContainer style={{ fontSize: "2rem" }} />
                    <form
                        className="enquiry-main-right"
                        onSubmit={handleSubmit}
                    >
                        <p className="enquiry-main-right-title">
                            {hotelData?.data.name},
                            <span> {hotelData?.data.country}</span>
                        </p>
                        <div className="enquiry-form-inputs-container">
                            <AgentInput />
                            <QueryInput
                                setChildActive={setChildActiveFunction}
                                setChildNotActive={setChildNotActiveFunction}
                                setChildArray={childNumberHandler}
                            />
                        </div>
                        <div
                            className={`text-area-div ${
                                isChildAge ? "message-active" : ""
                            }`}
                        >
                            <textarea
                                className="enquiry-form-message-area"
                                placeholder="Any other request?"
                            ></textarea>
                            <div
                                className={`enquiry-children-ages ${
                                    isChildAge ? "children-active" : ""
                                }`}
                            >
                                {childNumber.map((child, index) => {
                                    return (
                                        <div
                                            className="child-age-box"
                                            key={index}
                                        >
                                            <label
                                                htmlFor="child_age"
                                                className="child-age-label"
                                            >{`Child ${
                                                index + 1
                                            } Age :`}</label>
                                            <input
                                                id="child_age"
                                                className="child-age-input"
                                                required
                                                type="number"
                                                min="0"
                                                max="18"
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="enquiry-form-buttons">
                            <p className="tnc">
                                - Child Age may vary from hotel to hotel
                            </p>
                            <div className="enquiry-form-buttons_container">
                                <button
                                    className="submit"
                                    formAction="submit"
                                    ref={submitRef}
                                >
                                    {querySent ? (
                                        <AiOutlineLoading3Quarters className="l-circle" />
                                    ) : (
                                        <p>Submit</p>
                                    )}
                                </button>
                                <button
                                    className="cancel"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Enquiry;
