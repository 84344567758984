import { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

// Style
import "./Testimonials.scss";

// Local Imports
import { appUrl } from "../../../config/appUrl";
import VerticalTitle from "../../../components/UI/VerticalTitle/VerticalTitle";

// ******* Testimonial Card Component ******* //

const TestimonialCard = ({ text, name, company }) => {
    return (
        <div className="testimonial-card">
            <div className="card-text">
                <p className="text">{text}</p>
            </div>
            <div className="testimonial-card-info">
                <p className="name">{name}</p>
                <p className="company">{company}</p>
            </div>
        </div>
    );
};

// ******* Testimonial Main Component ******* //

const Testimonials = () => {
    const [testimonialData, setTestimonialData] = useState();
    const navigate = useNavigate();

    // Getting Testimonials Data from api
    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.get(
                    `${appUrl.url}/testimonials?key=${appUrl.key}`
                );
                setTestimonialData(response.data);
            } catch (err) {
                console.log(`Error Occured! : ${err}`);
            }
        };
        getData();

        window.addEventListener("resize", () => {
            setWindowWidth(window.innerWidth);
        });
    }, []);

    // Windows dimension
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Slider Properties
    let settings = {};

    if (windowWidth >= 900) {
        settings = {
            // dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: 0,
            lazyload: true,
            swipeToSlide: true,
            focusOnSelect: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
        };
    } else {
        settings = {
            // dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "60px",
            lazyload: true,
            swipeToSlide: true,
            focusOnSelect: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
        };
    }

    // Carousel Right Click Icon
    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            <div className="carousel-right">
                <RightOutlined onClick={onClick} />
            </div>
        );
    }

    // Carsouel Left Click Icon
    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <div className="carousel-left">
                <LeftOutlined onClick={onClick} />
            </div>
        );
    }

    // Functions

    // function to navigate to feedback page
    const clickHandler = () => {
        navigate("/feedback");
    };

    return (
        <div className="testimonials-container">
            <VerticalTitle title="Testimonials" right="-360px" top="170px" />
            <div className="testimonials-card-container">
                <Slider {...settings}>
                    {testimonialData?.map((data) => (
                        <TestimonialCard
                            text={data.feedbackMessage}
                            name={data.name}
                            company={data.companyName}
                            key={data.id}
                        />
                    ))}
                </Slider>
            </div>
            <div className="testimonials-redirect-text">
                <p className="redirect-text" onClick={clickHandler}>
                    Click here if you'd like to say some 'Nice - Not So Nice'
                    things about Us? 😉
                </p>
            </div>
        </div>
    );
};

export default Testimonials;
