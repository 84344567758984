import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import DotLoading from "../../UI/DotLoading/DotLoading";

// Style
import "./Dropdown.scss";

const Dropdown = ({ ShowModal, destination, hotels, title }) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [FilteredHotels, setFilteredHotels] = useState([]);

    useEffect(() => {
        // setData(hotels);
        // // sorting by city
        // let sortedArr;
        // try {
        //     sortedArr = data.sort((a, b) =>
        //         a.city.toLowerCase().localeCompare(b.city.toLowerCase())
        //     );
        // } catch (e) {
        //     sortedArr = data;
        // }

        // // filtered hotels by brands
        // // const hotelsByBrands = sortedArr.filter(i => i.name.replace(/[^a-zA-Z ]/g, "").toLowerCase() === destination.replace(/[^a-zA-Z ]/g, "").toLowerCase());
        // const hotelsByBrands = sortedArr.filter(
        //     (i) => i.brand_id === destination
        // );

        // setFilteredHotels(hotelsByBrands);

        setData(hotels);
        // sorting by city
        var sortedArr, hotelsByDestinations;
        try {
            sortedArr = data.sort((a, b) =>
                a.city.toLowerCase().localeCompare(b.city.toLowerCase())
            );
        } catch (e) {
            sortedArr = data;
        }

        // filtered hotels by brands
        // const hotelsByBrands = sortedArr.filter(i => i.name.replace(/[^a-zA-Z ]/g, "").toLowerCase() === destination.replace(/[^a-zA-Z ]/g, "").toLowerCase());
        const hotelsByBrands = sortedArr.filter(
            (i) => i.brand_id === destination
        );

        // filtered hotels by destinations
        try {
            hotelsByDestinations = sortedArr.filter(
                (i) => i.destination_id === destination
            );
        } catch (e) {
            hotelsByDestinations = [];
            for (var i = 0; i < sortedArr.length; i++) {
                if (sortedArr[i].destination) {
                    if (
                        sortedArr[i].destination
                            .replace(/[^a-zA-Z ]/g, "")
                            .toLowerCase() ===
                        destination.replace(/[^a-zA-Z ]/g, "").toLowerCase()
                    ) {
                        hotelsByDestinations.push(sortedArr[i]);
                    }
                }
            }
        }

        if (title === "brand") {
            setFilteredHotels(hotelsByBrands);
        } else if (title === "dest") {
            setFilteredHotels(hotelsByDestinations);
        }
    }, [hotels, title, data, destination]);

    return (
        <>
            <div className="dropdown">
                <div className="dropdown_wrapper">
                    <div className="top">
                        <h3>Where shall we take you today?</h3>
                    </div>
                    <div className="hotels_names_div">
                        {FilteredHotels.length === 0 && <DotLoading />}
                        {FilteredHotels.map((hotels) => (
                            <div
                                className="hotels_names_selector"
                                key={hotels.id}
                                onClick={() => {
                                    window.innerWidth > 700
                                        ? window.open(
                                              `/luxury-living-portfolio/${hotels.url_param_key}`,

                                              "_blank"
                                          )
                                        : navigate(
                                              `/luxury-living-portfolio/${hotels.url_param_key}`
                                          );
                                }}
                            >
                                <div className="hotel_image_container">
                                    <img
                                        className="hotel_image"
                                        src={hotels.pictures[0].url}
                                        alt="Hotel"
                                    />
                                </div>
                                <div className="hotels_names_">
                                    <div>
                                        <span>{hotels.name},</span>
                                        <span style={{ color: "red" }}>
                                            {" "}
                                            {hotels.city}
                                        </span>
                                    </div>
                                    <span>{hotels.location}</span>
                                    {/* //? uncomment this code below if you want to add a tag called New in list */}
                                    {/* {hotels.collaboration && (
                                        <div className="new_hotel">New</div>
                                    )} */}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="cancel_btn" onClick={ShowModal}>
                        <CloseOutlined className="icon" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dropdown;
