import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { BsXLg } from "react-icons/bs";

// Style
import "./Portfolio.scss";

// Local Imports
import {
    setBrands,
    setBrandsAll,
    setDestinations,
    setDestinationsAll,
    setSearchType,
} from "../../redux/portfolioReducer";
import DotLoading from "../../components/UI/DotLoading/DotLoading";
import { appUrl } from "../../config/appUrl";
import HotelSectionBrands from "../../components/PortfolioPage/HotelSectionBrands/HotelSectionBrands";
import HotelSectionDestination from "../../components/PortfolioPage/HotelSectionDestination/HotelSectionDestination";

import Header from "../../components/UI/Header/Header";
import Social from "../../components/UI/Social/Social";

function Portfolio() {
    const portfolioState = useSelector((state) => state.portfolio);
    const dispatch = useDispatch();
    const searchRef = useRef();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Getting brands and destination data
        axios.get(`${appUrl.url}/brands?key=${appUrl.key}`).then((response) => {
            dispatch(setBrands(response.data));
            dispatch(setBrandsAll(response.data));
        });
        axios
            .get(`${appUrl.url}/destinations?key=${appUrl.key}`)
            .then((response) => {
                dispatch(setDestinations(response.data));
                dispatch(setDestinationsAll(response.data));
                setIsLoading(false);
            });
    }, [dispatch]);

    // Handling button Click on portfolio
    function handleSearchButton(event) {
        let innerText = event.target.innerText;

        if (innerText === "BRANDS") {
            // Setting search type to = destination
            dispatch(setSearchType("brands"));
            dispatch(setDestinations(portfolioState.destinationsAll));
        } else {
            // setting search type to = brands
            dispatch(setSearchType("destination"));
            dispatch(setBrands(portfolioState.brandsAll));
        }
    }

    const [searchBar, setSearchBar] = useState(false);

    // function to add a cross button on search bar
    function handleSearchBar(event) {
        event.preventDefault();
        if (event.target.value !== "") {
            setSearchBar(true);
        } else {
            setSearchBar(false);

            // Setting the brands and destination to all when input get empty by user
            // Asking the user if the
            if (portfolioState.searchType === "brands") {
                dispatch(setBrands(portfolioState.brandsAll));
            } else {
                dispatch(setDestinations(portfolioState.destinationsAll));
            }
        }
    }

    // function to clear the search result
    function handleClearSearch() {
        searchRef.current.value = "";
        setSearchBar(false);

        // Reseting all section when clicked on cross
        dispatch(setBrands(portfolioState.brandsAll));
        dispatch(setDestinations(portfolioState.destinationsAll));
    }

    function handleSearch(event) {
        event.preventDefault();
        const myInputBox = document.getElementById("input-box");
        const searchingText = event.target[0].value;
        if (portfolioState.searchType === "brands") {
            axios
                .get(
                    `${appUrl.url}/search/brand?q=${searchingText}&&key=${appUrl.key}`
                )
                .then((res) => dispatch(setBrands(res.data)));
        } else {
            axios
                .get(
                    `${appUrl.url}/search/destination?q=${searchingText}&&key=${appUrl.key}`
                )
                .then((res) => dispatch(setDestinations(res.data)));
        }

        if (myInputBox) {
            myInputBox.blur();
        }
    }

    return (
        <div className="portfolio">
            <Header />
            <Social />
            <div className="portfolio-landing-page">
                <div className="portfolio-landing-page-wrapper">
                    <h1 className="heading">portfolio</h1>
                    <p className="portfolio-page-para">
                        Having a close circle of friends makes life a breeze!
                        Wouldn't you agree? And ours has been a breeze since the
                        day we started making friends in our business. Most of
                        our hotel partners have been working with us since our
                        inception & they continue to watch our back as we
                        continue to watch theirs! And its no joke when we claim
                        to have taken quite a few bullets for them...so what if
                        it was while playing paintball...bullets are bullets!
                    </p>
                </div>
            </div>
            <div className="search-by">
                <div className="buttons">
                    <button
                        onClick={handleSearchButton}
                        className={`search-btn ${
                            portfolioState.searchType === "brands"
                                ? "search-btn-active"
                                : ""
                        }`}
                    >
                        Brands
                    </button>
                    <button
                        onClick={handleSearchButton}
                        className={`search-btn ${
                            portfolioState.searchType === "destination"
                                ? "search-btn-active"
                                : ""
                        }`}
                    >
                        Destination
                    </button>
                </div>
                <form className="portfolio-search" onSubmit={handleSearch}>
                    <input
                        type="text"
                        ref={searchRef}
                        id="input-box"
                        placeholder={
                            portfolioState.searchType === "brands"
                                ? "Search Brands"
                                : "Search Destinations"
                        }
                        onChange={handleSearchBar}
                    ></input>
                    {searchBar && (
                        <div className="portfolio-search-cross-btn">
                            <BsXLg onClick={handleClearSearch} />
                        </div>
                    )}
                </form>
            </div>
            <div className="cards-container">
                {isLoading && <DotLoading />}
                <div className="cards-container-main">
                    {portfolioState.searchType === "brands" ? (
                        <HotelSectionBrands hotelData={portfolioState.brands} />
                    ) : (
                        <HotelSectionDestination
                            hotelData={portfolioState.destinations}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Portfolio;
