import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./City.scss";

function City({ cities, allHotels }) {
    return (
        <div className="hotel-list">
            {cities?.map((hotel) => {
                return <List key={hotel} hotel={hotel} allHotels={allHotels} />;
            })}
        </div>
    );
}

function List({ hotel, allHotels }) {
    const [showHotels, setShowHotels] = useState(false);
    const navigate = useNavigate();

    function handleClick() {
        setShowHotels(!showHotels);
    }

    return (
        <div className="list-container">
            <p className="hotel-destination" onClick={handleClick}>
                {hotel}
            </p>
            {showHotels && (
                <>
                    {allHotels
                        .filter((hotelMain) => hotelMain.city === hotel)
                        .map((hotel) => (
                            <div
                                key={hotel.id}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <p
                                    className="hotel-name"
                                    onClick={() =>
                                        navigate(
                                            `/luxury-living-portfolio/${hotel.url_param_key}`
                                        )
                                    }
                                >
                                    {hotel.name}
                                    {hotel.collaboration && (
                                        <span className="new-tag">NEW</span>
                                    )}
                                </p>
                            </div>
                        ))}
                </>
            )}
        </div>
    );
}

export default City;
