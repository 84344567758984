import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isListOpen: false,
    hotelList: [],
};

export const hotelListSlice = createSlice({
    name: "hotelList",
    initialState,
    reducers: {
        setHotelList: (state) => {
            state.isListOpen = !state.isListOpen;
        },
        setHotelListData: (state, action) => {
            state.hotelList = action.payload;
        },
    },
});

export const { setHotelList, setHotelListData } = hotelListSlice.actions;

export default hotelListSlice.reducer;
