import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { FaTimes } from "react-icons/fa";
import { useParams } from "react-router-dom";

// Style
import "./Offers.scss";

// Local Imports
import { appUrl } from "../../config/appUrl";
import Loading from "../../utils/Loading/Loading";
import OffersCard from "../../components/OffersPage/OffersCard/OffersCard";
import Header from "../../components/UI/Header/Header";
import Social from "../../components/UI/Social/Social";
import OfferCardSearchButton from "../../components/OffersPage/OfferCardSearchTag/OfferCardSearchButton";
import { setOffers } from "../../redux/offersReducer";

function Offers() {
    const [offerData, setOfferData] = useState([]);
    const [offerDataAll, setOfferDataAll] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [offerSearchData, setOfferSearchData] = useState([1]);
    const [tagNotFoundLoading, setTagNotFoundLoading] = useState(true);
    const searchRef = useRef();
    const searchTagList = [
        "Add Ons",
        "Comp kids",
        "Celebration",
        "Discount",
        "Early Bird",
        "Flat Rate",
        "Misc.",
        "Package",
        "Repeat Guest",
        "Stay Pay",
        "Split Stay",
        "Upgrade",
        "Value Add",
    ];

    const dispatch = useDispatch();
    const offersList = useSelector((state) => state.offers.offers);
    const offerButtonState = useSelector((state) => state.offerButtonTag);
    const activeSearch = useParams();

    useEffect(() => {
        // Setting data if the offer page have a search link i.e. offer/:searchKey

        // if page does have a search link get the data related to link and get all offer data
        if (activeSearch.searchKey !== undefined) {
            fetch(`${appUrl.url}/offers?key=${appUrl.key}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not OK");
                    }
                    return response.json();
                })
                .then((data) => {
                    setOfferDataAll(data);
                    const newOffers = data.filter(
                        (res) =>
                            res.url_hotel_name_key === activeSearch.searchKey
                    );
                    if (newOffers.length === 0) {
                        setTagNotFoundLoading(false);
                    } else {
                        setOfferData(newOffers);
                        setOfferSearchData(newOffers);
                    }
                })
                .catch((error) => {
                    console.log(
                        "There has been a proble with your fetch operation: ",
                        error
                    );
                });

            // if page doesn't have search link then get the data related to all of the offer and also store in it a second variable too '_'
        } else {
            // if offers data is allready available in redux state then just get the data
            if (offersList.length !== 0) {
                setOfferData(offersList);
                setOfferDataAll(offersList);
            }
            // else make the api call and save the data to redux state
            else {
                axios
                    .get(`${appUrl.url}/offers?key=${appUrl.key}`)
                    .then((response) => {
                        setOfferData(response.data);
                        setOfferDataAll(response.data);
                        dispatch(setOffers(response.data));
                    });
            }
        }
    }, [activeSearch.searchKey, offersList, dispatch]);

    const offerComponent = offerData.map((data) => {
        return (
            <OffersCard
                key={data.id}
                hotelImage={data.thumbnail}
                hotelHeading={data.hotel}
                offerName={data.name}
                room_cate={data.categories}
                offerDetail={data.description}
                hotelId={data.hotel_id}
                startDate={data.travel_start_date[1]}
                endDate={data.travel_end_date[1]}
                bookEndDate={data.book_by_end_date[1]}
            />
        );
    });

    const searchType = (text) => {
        axios
            .get(`${appUrl.url}/search/offer?q=${text}&&key=${appUrl.key}`)
            .then((response) => {
                if (response.data.length === 0) {
                    setOfferSearchData(response.data);
                    setSearchLoading(false);
                } else {
                    setOfferData(response.data);
                    setOfferSearchData(response.data);
                    setSearchLoading(false);
                    setTagNotFoundLoading(true);
                }
            });
    };

    const searchTag = (text) => {
        const newData = (
            offerSearchData.length === 1 && offerSearchData[0] === 1
                ? offerDataAll
                : offerSearchData
        ).filter((item) => item.name === text);

        if (newData.length === 0) {
            setTagNotFoundLoading(false);
            setOfferData(newData);
        } else {
            setOfferData(newData);
            setTagNotFoundLoading(true);
        }
    };

    // Debouncing for performance
    const handleText = debounce((text) => {
        searchType(text);
        setSearchLoading(true);
    }, 1000);

    function handleAllButtonClick() {
        if (offerSearchData[0] === 1) {
            setOfferData(offerDataAll);
        } else {
            setOfferData(offerSearchData);
        }
        setTagNotFoundLoading(true);
    }

    return (
        <>
            {offerData.length === 0 && tagNotFoundLoading ? (
                <Loading height="100vh" />
            ) : (
                <>
                    <Header />
                    <div className="offer-search-container">
                        <div className="offer-search-form">
                            <form
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    searchType(event.target[0].value);
                                    setSearchLoading(true);
                                }}
                            >
                                <input
                                    ref={searchRef}
                                    onChange={(event) => {
                                        event.preventDefault();
                                        return handleText(event.target.value);
                                    }}
                                    className="offer-search-main"
                                    placeholder="Search Offers by hotel name or city..."
                                />
                            </form>
                            <button
                                className="offer-search-clear"
                                onClick={() => {
                                    setOfferData(offerDataAll);
                                    searchRef.current.value = "";
                                    setTagNotFoundLoading(true);
                                    setOfferSearchData([1]);
                                }}
                            >
                                <span className="offer-search-clear-text">
                                    Clear Search
                                </span>
                                <span className="offer-search-clear-icon">
                                    <FaTimes className="offer-search-clear-icon-main" />
                                </span>
                            </button>
                        </div>

                        <div className="offer-search-tag-container">
                            {searchTagList.map((tag) => {
                                return (
                                    <OfferCardSearchButton
                                        key={tag}
                                        searchText={searchTag}
                                        buttonText={tag}
                                        sendActive={
                                            offerButtonState === tag
                                                ? "btnActive"
                                                : ""
                                        }
                                    />
                                );
                            })}
                            <button
                                className="offer-search-all-button"
                                onClick={handleAllButtonClick}
                            >
                                #All
                            </button>
                        </div>
                    </div>
                    <div className="offer-card-container">
                        {searchLoading ? (
                            <Loading height="20vw" />
                        ) : offerSearchData.length === 0 ? (
                            <></>
                        ) : (
                            offerComponent
                        )}
                        {tagNotFoundLoading !== true && (
                            <p style={{ fontSize: "2rem" }}>
                                No Results Found!
                            </p>
                        )}
                    </div>
                    <Social />
                </>
            )}
        </>
    );
}

export default Offers;
