import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import CircleLoading from "../CircleLoading/CircleLoading";

// Styles
import styles from "./Overlay.module.scss";

const Overlay = ({ loading, fullScreen, size, customZIndex }) => {
    const [loadingText, setLoadingText] = useState("Getting things ready!");
    useEffect(() => {
        // When home is loading make body hidden
        document.body.style.overflowY = "hidden";

        setTimeout(() => {
            setLoadingText("You still waiting?");
        }, 6000);

        // After loading is complete make body scroll
        return () => {
            document.body.style.overflowY = "scroll";
            clearTimeout();
        };
    }, []);

    return ReactDOM.createPortal(
        <div className={styles.overlay} style={{ zIndex: customZIndex }}>
            {loading && <CircleLoading size={size} />}
            {loading && <p className={styles.loading_text}>{loadingText}</p>}
        </div>,
        document.getElementById("lx_overlay")
    );
};

export default Overlay;
