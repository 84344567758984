import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

// Styles
import "./OurValues.scss";

// Local Imports
import { valuesdata } from "../../../data/valuesdata";
import VerticalTitle from "../../UI/VerticalTitle/VerticalTitle";

// Card Component
const ValuesCard = ({ title, image, text }) => {
    return (
        <div className="card-container">
            <div className="card-header">
                <img src={image} className="card-image" alt="title" />
                <p className="card-title">{title}</p>
            </div>
            <p
                className="card-info"
                dangerouslySetInnerHTML={{ __html: text }}
            ></p>
        </div>
    );
};

// Main Component
const OurValues = () => {
    useEffect(() => {
        window.addEventListener("resize", () => {
            setWindowWidth(window.innerWidth);
        });
    });

    // Windows dimension
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Slider Properties
    let settings = {};

    if (windowWidth >= 900) {
        settings = {
            // dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: 0,
            lazyload: true,
            swipeToSlide: true,
            focusOnSelect: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
        };
    } else {
        settings = {
            // dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "60px",
            lazyload: true,
            swipeToSlide: true,
            focusOnSelect: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
        };
    }

    // Carousel Right Click Icon
    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            <div className="carousel-right">
                <RightOutlined onClick={onClick} />
            </div>
        );
    }

    // Carsouel Left Click Icon
    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <div className="carousel-left">
                <LeftOutlined onClick={onClick} />
            </div>
        );
    }

    return (
        <section className="values-container">
            <VerticalTitle title="Our Values" />
            <div className="values-slider-container">
                <Slider {...settings}>
                    {valuesdata.map((data) => (
                        <ValuesCard
                            key={data.id}
                            title={data.title}
                            image={data.img}
                            text={data.content}
                        />
                    ))}
                </Slider>
            </div>
        </section>
    );
};

export default OurValues;
