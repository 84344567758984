
import "./OfferCardSearchButton.scss";

function OfferCardSearchButton(props) {

    function handleButtonClick() {

            props.searchText(props.buttonText)
        
    }

    return (
        <button
            className={`offer-search-tag`}
            onClick={handleButtonClick}
        >
            #{(props.buttonText).split(" ").join("")}
        </button>
    );
}

export default OfferCardSearchButton;
