import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { BsXLg } from "react-icons/bs";

// Style
import "./HotelListPage.scss";

// Local Imports
import { setHotelListData } from "../../redux/hotelListReducer";
import CountryList from "./CountryList/CountryList";
import { appUrl } from "../../config/appUrl";

function HotelList() {
    const dispatch = useDispatch();
    const [gotData, setGotData] = useState(false);
    const hotelListState = useSelector((state) => state.hotelList.hotelList);
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.overflowY = "hidden";
        if (hotelListState.length === 0) {
            axios
                .get(`${appUrl.url}/hotels?key=${appUrl.key}`)
                .then((response) => {
                    dispatch(setHotelListData(response.data));
                    setGotData(true);
                });
        } else {
            dispatch(setHotelListData(hotelListState));
            setGotData(true);
        }

        return () => (document.body.style.overflowY = "scroll");
    }, [dispatch]);

    return (
        <div className="hotels-list-container">
            <div className="hotels-list-box">
                <div className="hotels-list-header">
                    <BsXLg size={30} onClick={() => navigate(-1)} />
                </div>
                <div className="hotels-list-main">
                    {!gotData && (
                        <div className="loading-circle-box">
                            <AiOutlineLoading3Quarters
                                size={60}
                                className="loading-circle"
                            />
                        </div>
                    )}
                    {gotData && (
                        <div className="hotels-list-left">
                            <div className="continent-box">
                                <h2 className="continent">Asia</h2>
                                {gotData && (
                                    <CountryList
                                        allHotels={hotelListState}
                                        continentName="Asia"
                                    />
                                )}
                            </div>
                            {/* <div className="continent-box">
                                <h2 className="continent australia">
                                    Australia
                                </h2>
                                {gotData && (
                                    <CountryList
                                        allHotels={hotelListState}
                                        continentName="australia"
                                    />
                                )}
                            </div> */}
                            <div className="continent-box">
                                <h2 className="continent north-america">
                                    North America
                                </h2>
                                {gotData && (
                                    <CountryList
                                        allHotels={hotelListState}
                                        continentName="North America"
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    {gotData && (
                        <div className="hotels-list-right">
                            <div className="continent-box">
                                <h2 className="continent africa">Africa</h2>
                                {gotData && (
                                    <CountryList
                                        allHotels={hotelListState}
                                        continentName="Africa"
                                    />
                                )}
                            </div>
                            <div className="continent-box">
                                <h2 className="continent europe">Europe</h2>
                                {gotData && (
                                    <CountryList
                                        allHotels={hotelListState}
                                        continentName="Europe"
                                    />
                                )}
                            </div>
                            {/* <div className="continent-box">
                                <h2 className="continent south-america">
                                    South America
                                </h2>
                                {gotData && (
                                    <CountryList
                                        allHotels={hotelListState}
                                        continentName="south america"
                                    />
                                )}
                            </div> */}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default HotelList;
